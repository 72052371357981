import React, {useRef, useEffect, useState} from 'react';
import '../header.css';
import KatilasCard from "../katilai/KatilasCard";
import HeaderTree from "./headerTree";
import { useNavigate } from "react-router-dom";
import { useLanguage } from './../LanguageContext';

function Header(props) {
    const {katilai, activeCategory} = props
    let [language, languageTexts, languageKatilai, toggleLanguage] = useLanguage();
    const translation = languageTexts;
    const slidesContainerRef = useRef();
    let slideRef = useRef();
    let next = useRef();
    let imageContainerRef = useRef();
    let imageSize = useRef();
    const [disableKatilaiCarousel, setdisableKatilaiCarousel] = useState(false);
    const [stateChangeMoveCarousel, setStateChangeMoveCarousel] = useState(false);
    const [currentKatilas, setCurrentKatilas] = useState(0);
    const navigate = useNavigate()


    useEffect(() => {
        const htmlElement = document.querySelector("html");
        htmlElement.style.scrollBehavior = 'auto';
        window.scrollTo(0, 0);
        htmlElement.style.scrollBehavior = 'smooth';
      }, [])
      
    //kokie katilai karuselej
    //TODO KAZKAIP SUGALVOT
    const galleryKatilai = [0,1,2];
    useEffect(() => {
        if(!disableKatilaiCarousel){
                automaticSlideKatilai();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateChangeMoveCarousel]);
    useEffect(() => {
        setTimeout(() => {
            automaticSlide();
        }, 30000);
        stateChangeMove();
        setCurrentKatilas(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //weird workaround kad kai rodyklyte paspaudi nebeveikia katilu karusele
    //jeigu setTimeout'e palieku funkcija kaip su fotkem nebegaliu sustabdyt, net ir pakeitus state funkcijos kazkodel nemato to 
    //ir viduje funkcijos tikrindamas matau nepasikeitusi state
    //todel keiciu papildoma state kiekviena kart kad leist useEffect, kuris leistu funkcija karuseles funkcija, bet pries tai tikrina ar nedisabled
    const stateChangeMove = () =>{
        setTimeout(() => {
            setStateChangeMoveCarousel(prevState => {
                let please = !prevState;
                return please;
            });
            stateChangeMove();
        }, 10000);
    }   

    function slideNext(e){
        setCurrentKatilas((old) =>{
            if(old === galleryKatilai.length - 1) return old;
            else return old+1;
        })
        //sustabdo automatine karusele
        setdisableKatilaiCarousel(true);
        document.getElementById("slide-arrow-next").disabled = true;
        const slideWidth = slideRef.current.clientWidth;
        //jeigu netucia tuo paciu metu rodyklyte paspaude kaip automatine karusele suko kad atrodytu gerai
        //same ir slidePrev tik -
        const newSlidePos = (slidesContainerRef.current.scrollLeft + slideWidth)/slideWidth;
        slidesContainerRef.current.scrollLeft = Math.round(newSlidePos) * slideWidth;
        setTimeout(function(){document.getElementById("slide-arrow-next").disabled = false;},500);
    }

    function slidePrev(e){
        setCurrentKatilas((old) =>{
            if(old === 0) return 0;
            else return old-1;
        })
        setdisableKatilaiCarousel(true);
        document.getElementById("slide-arrow-prev").disabled = true;
        const slideWidth = slideRef.current.clientWidth;
        const newSlidePos = (slidesContainerRef.current.scrollLeft - slideWidth)/slideWidth;
        slidesContainerRef.current.scrollLeft = Math.round(newSlidePos) * slideWidth;
        setTimeout(function(){document.getElementById("slide-arrow-prev").disabled = false;},500);
    }
    function automaticSlide(e){
        if(imageSize.current){
            const slideWidth = imageSize.current.clientWidth;
            if(imageContainerRef.current.scrollLeft > ((2*slideWidth)-50))  imageContainerRef.current.scrollLeft -= 2*slideWidth;
            else imageContainerRef.current.scrollLeft += slideWidth;
            setTimeout(automaticSlide,30000);
        }
    }
    function automaticSlideKatilai(e){
        setCurrentKatilas((old) =>{
            if(old === galleryKatilai.length - 1) return 0;
            else return old+1;
        })
        const containerSizeMax = galleryKatilai.length -1;
        if(slideRef.current){
            const slideWidth = slideRef.current.clientWidth;
            if(slidesContainerRef.current.scrollLeft > ((containerSizeMax*slideWidth)-50))  slidesContainerRef.current.scrollLeft -= containerSizeMax*slideWidth;
            else slidesContainerRef.current.scrollLeft += slideWidth;
            //setTimeout(automaticSlideKatilai,10000);
        }
    }

    function goToCurrentKatilas(){
        let currentKatilasNr = currentKatilas + 1;
        
        // DAR BAISESNIS HACK KAD NEREIKTU PERRASINET KODO

        // jei trecia pozicija - ekon max (3 katilas)
        var nrForNav = 3

        // jei pirma pozicija - bio kompakt (2 katilas)
        if(currentKatilasNr === 1){
            nrForNav = 2
        // jei antra pozicija - pellet uni (1 katilas)
        }else if(currentKatilasNr === 2){
            nrForNav = 1
        }

        navigate("/katilai/"+nrForNav);
        // SENAS
        // navigate("/katilai/"+currentKatilasNr);
    }
    
  return <>
  <div className='parent'>
    <div className='header_image'>
        <div className='animationImages' ref={imageContainerRef}>
            <div className='randomImageDiv'><img ref={imageSize} src='assets/landing_01_long.jpg' className='randomImage' alt='landingImage1'></img></div>
            <div className='randomImageDiv'><img src='assets/landing_02_long.jpg' className='randomImage' alt='landingImage2'></img></div>
            <div className='randomImageDiv'><img src='assets/landing_03_long.jpg' className='randomImage' alt='landingImage3'></img></div>
        </div>
        <HeaderTree activeCategory={activeCategory}/>
        
        <div className='sukis'>
            <h1 className='sukis1' style={{fontWeight: "600"}}>{translation[9].text}</h1>
            <h1 className='sukis2' style={{fontWeight: "600"}}>{translation[10].text}</h1>
        </div>
    </div>
    <section className="slider-wrapper">
        <ul className="slides-container" id="slides-container" ref={slidesContainerRef}>
            {/* SENAS */}
            {/* {katilai.map((katilas, index) => (
                galleryKatilai.includes(index) ?
                <li key={katilas.id} className="slide" ref={slideRef}><KatilasCard key={katilas.id +"card" + katilas.name} katilas={katilas}></KatilasCard></li>
                :null
            ))} */}

            {/* HACK KAD NEREIKTU PERRASINET KODO */}
            {katilai[1] ? <li key={katilai[1].id} className="slide" ref={slideRef}><KatilasCard key={katilai[1].id +"card" + katilai[1].name} katilas={katilai[1]}></KatilasCard></li>: null}
            {katilai[0] ? <li key={katilai[0].id} className="slide" ref={slideRef}><KatilasCard key={katilai[0].id +"card" + katilai[0].name} katilas={katilai[0]}></KatilasCard></li>: null}
            {katilai[2] ? <li key={katilai[2].id} className="slide" ref={slideRef}><KatilasCard key={katilai[2].id +"card" + katilai[2].name} katilas={katilai[2]}></KatilasCard></li>: null}

        </ul>
        <button className="slide-arrow" id="slide-arrow-prev" onClick={slidePrev}>
            <img style={{height: "55px", width: "55px"}} src='../assets/rod_k.png' alt='<'></img>
        </button>
        <button className="slide-arrow" id="slide-arrow-next" ref={next} onClick={slideNext}>
            <img style={{height: "55px", width: "55px"}} src='../assets/rod_d.png' alt='>'></img>
        </button>
        <button className='DetailsButton' onClick={() => goToCurrentKatilas()}>{translation[14].text}</button>
    </section>
  </div>
  </>;
}

export default Header;
