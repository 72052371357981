import { Katilas } from "./Katilas";

export const MOCK_KATILAI = [
    new Katilas({
        id: 1,
        name: "Pellet Uni",
        type: "Granulinio kuro",
        imageUrl: "assets/pellet_uni.png",
        pjuvis: "assets/pellet_uni_pjuvis.png",
        feature: "Aukščiausia klasė",
        price: 3400,
        pricing: [
            [12, 3400, 0, [0, 30, 80, 110]],
            [16, 3530, 0, [0, 30, 80, 110]],
            [20, 3650, 120, [0, 30, 80, 110]],
            [26, 4070, 160, [0, 30, 80, 110]],
            [36, 4330, 160, [0, 30, 80, 110]],
        ],
        minPower: 12,
        maxPower: 36,
        power: [12, 16, 20, 26, 36],
        minArea: 120,
        maxArea: 360,
        efficiencyClass: "A++",
        fuelTypes: ["Granulės", "Malkos", "Briketai", "Anglys"],
        heatExchangerWarranty: 6,
        certificate: 5,
        tableData: [
            ["Apšildomas plotas", "iki m\u00b2", 120, 160, 200, 260, 360],
            ["Pakuros gylis", "mm", 455, 455, 455, 505, 505],
            ["Kuro įkrovimas", "l/dm\u00b3", 55, 55, 65, 84, 93],
            ["Kuro talpa", "l/dm\u00b3", 230, 230, 250, 270, 270],
            ["Rotacinis granulių degiklis", "kW", "4-16", "4-16", "5-20", "6-26", "8-36"],
            ["Šilumokaičio plotas", "m\u00b2", 2.2, 2.6, 2.9, 3.4, 3.7],
            ["Kuro užkrovimo angos dydis", "cm", "29x23", "29x23", "34x23", "39x23", "44x23"],
            ["Horizontalių šilumokaičių kiekis", "vnt", 3, 4, 4, 4, 4],
            ["Vandens tūris katile", "l", 59, 63, 68, 80, 85],
            ["Masė", "kg", 260, 280, 310, 360, 380],
            ["Reikalaujama trauka dūmtraukyje", "Pa", 12, 13, 14, 15, 15],
        ],
        additionalData: [
            ["Minimali katilo darbo temperatūra", "60 \u2070C"],
            ["Maksimali katilo darbo temperatūra", "90 \u2070C"],
            ["Šildymo efektyvumas", "90%"],
            ["Dūmtraukio skersmuo vidus/išorė", "150/160 mm"],
            ["Hidraulinių jungčių matmenys", "G 1 \u00b9\u{141F}\u2074 colių"],
            ["Maksimalus eksploatacinis slėgis", "1.5 bar"],
        ],
        selections: [
            ["Kuro talpa", "230 l", "260 l", "320 l", "350 l"],
            [
                ["Degiklis"],
                ["12", "4-16 kW"],
                ["16", "4-16 kW"],
                ["20", "4-16 kW", "5-20 kW"],
                ["26", "5-20 kW", "6-26 kW"],
                ["36", "6-26 kW", "8-36 kW"],
            ]
        ],
        dimensions: [
            ["A", "mm", 1150, 1260, 1260, 1260, 1260],
            ["B", "mm", 440, 440, 490, 540, 590],
            ["C", "mm", 640, 640, 640, 690, 690],
        ],
        degiklis: "„Kipi Rot Power“",
        degiklisData: [
            ["Galia", "10-36 kW"],
            // ["Degimo temperatūra", "850 \u2070C"],
            // ["Dydis", "890 x 380 x 450 mm"],
            // ["Jungtis", "D90 mm"],
        ],
        icons: ["4vert_01", "kera_def_02", "rotac_03", "tubul_04", "2deg_05", "modern_06", "krypt_07"],
        iconLocations: [[245, 150], [208, 350], [320, 380], [245, 150], [242, 330], [315, 70], [355, 170]],
        description: "Aukščiausios kategorijos ir techninio lygio granulinio kuro katilas su keturių vertimų šilumokaičiu ir keramikos deflektoriumi. Ketaus ardeliai ir keramikos deflektorius skatina ir užtikrina sklandų degimą. Tai leidžia pasiekti didesnį nei 90% katilo naudingumo koeficientą. Pilnai automatinis rotacinis degiklis skirtas deginti prastesnės kokybės ir didesnio peleningumo granules. Besisukanti degimo kamera padeda visiškai sudeginti kurą ir neleidžia formuotis šlakui. Valdiklis kontroliuoja visus katilinės valdymo ir katilo degimo procesus.",
        burnerDescription: "Pilnai automatinis granulinis degiklis, skirtas deginti geros ir vidutinės kokybės granules. Degiklis turi automatinio valymo mechanizmą – rotacinę besisukančią degimo ir oro padavimo kamerą, kurios pagalba degiklis pats išsivalo nuo pelenų ir šlako.",
        burnerImage: "../assets/kipiRotPower.png",
        burnerInstructionsPdf: "../assets/pellet_uni_degiklio_instrukcija.pdf",
        dimensionsImage: "../assets/pelletUniDimensions.svg",
        certificatePdf: "../assets/Bandymu sertifikatas Pellet Uni.pdf",
        instructionsPdf: "../assets/pellet_uni_instrukcija.pdf",
        techSpecPdf: "../assets/pellet_uni_lt.pdf",
        controllerInstructionsPdf: "../assets/Valdiklio Plum 920 instrukcija.pdf",
       
        connectionSchemePdf: "../assets/pelletUniPajungimasLT.pdf",
    }),
    new Katilas({
        id: 2,
        name: "Bio Kompakt",
        type: "Granulinio kuro",
        imageUrl: "assets/bio_kompakt.png",
        pjuvis: "assets/bio_kompakt_pjuvis.png",
        feature: "Naujiena",
        price: 3400,
        pricing:[
            [12, 3400, 0, [0]],
            [16, 3550, 0, [0]],
            [20, 3700, 0, [0]],
        ],
        minPower: 12,
        maxPower: 20,
        power: [12, 16, 20],
        minArea: 120,
        maxArea: 200,
        efficiencyClass: "A++",
        fuelTypes: ["Granulės"],
        heatExchangerWarranty: 6,
        certificate: 5,
        tableData: [
            ["Apšildomas plotas", "iki m\u00b2", 120, 160, 200],
            ["Kuro talpa", "l/dm\u00b3", 120, 160, 160],
            ["Rotacinis granulių degiklis", "kW", "4-12", "4-16", "4-18"],
            ["Šilumokaičio plotas", "m\u00b2", 1.7, 2.0 , 2.4],
            ["Horizontalių šilumokaičių kiekis", "vnt", 2, 2, 2],
            ["Vandens tūris katile", "l", 44, 50, 58],
            ["Masė", "kg", 155, 175, 195],
        ],
        additionalData: [
            ["Minimali katilo darbo temperatūra", "60 \u2070C"],
            ["Maksimali katilo darbo temperatūra", "90 \u2070C"],
            ["Šildymo efektyvumas", "90%"],
            ["Dūmtraukio skersmuo vidus/išorė", "130/140 mm"],
            ["Hidraulinių jungčių matmenys", "G 1 \u00b9\u{141F}\u2074 colių"],
            ["Maksimalus eksploatacinis slėgis", "1.5 bar"],
            ["Reikalaujama trauka dūmtraukyje", "15-20 Pa"]
        ],
        selections: [
            [""],
            [""],
        ],
        dimensions: [
            ["A", "mm", 470, 520, 570],
            ["B", "mm", 645, 695, 754],
        ],
        degiklis: "„Kipi Rot Power“",
        degiklisData: [
            ["Galia", "10-20 kW"],
        ],
        icons: ["kera_deg_01", "kera_def_02", "rotac_03", "tubul_04", "did_sil_05", "kompakt_06", "krypt_07"],
        iconLocations: [[290, 386], [230, 360], [375, 380], [298, 325], [298, 325], [0, 0], [414, 345]],
        description: "Kompaktinės klasės granulinio kuro katilas su keramine degimo kamera, keraminiu deflektoriumi ir dideliu šilumokaičiu. Ilgaamžė aukštatemperatūrė keramika palaiko degimą bei užtikrina sklandų degimo procesą. Tai leidžia pasiekti didesnį nei 90% katilo naudingumo koeficientą. Pilnai automatinis rotacinis degiklis skirtas deginti prastesnės kokybės ir didesnio peleningumo granules. Besisukanti degimo kamera padeda visiškai sudeginti kurą ir neleidžia formuotis šlakui. Valdiklis kontroliuoja visus katilinės valdymo ir katilo degimo procesus.",
        burnerDescription: "Pilnai automatinis granulinis degiklis, skirtas deginti geros ir vidutinės kokybės granules. Degiklis turi automatinio valymo mechanizmą – rotacinę besisukančią degimo ir oro padavimo kamerą, kurios pagalba degiklis pats išsivalo nuo pelenų ir šlako.",
        burnerImage: "../assets/kipiRotPower.png",
        burnerInstructionsPdf: "../assets/pellet_uni_degiklio_instrukcija.pdf",
        dimensionsImage: "../assets/bioKompaktDimensions.svg",
        certificatePdf: "../assets/Bio Kompakt 10-20_Bandymu sertifikatas Nr. 02.24-ST [LT].pdf",
        instructionsPdf: "../assets/bio_kompakt_instrukcija.pdf",
        techSpecPdf: "../assets/bio_kompakt_lt.pdf",
        controllerInstructionsPdf: "../assets/Valdiklio Plum 920 instrukcija.pdf",
        connectionSchemePdf: "../assets/bioKompaktPajungimasLT.pdf",
    }),
    new Katilas({
        id: 3,
        name: "Ekon Max",
        type: "Kieto kuro",
        imageUrl: "assets/ekon_max.png",
        pjuvis: "assets/ekon_max_pjuvis.png",
        feature: "Efektyvus",
        price: 1950,
        pricing:[
            [16, 1950, 0, [0]],
            [20, 2150, 0, [0]],
            [25, 2350, 0, [0]],
            [30, 2550, 0, [0]],
            [40, 2850, 0, [0]],
        ],
        minPower: 16,
        maxPower: 40,
        power: [16, 20, 25, 30, 40],
        minArea: 160,
        maxArea: 400,
        efficiencyClass: "A+",
        fuelTypes: ["Malkos", "Briketai", "Pjuvenos", "Anglys"],
        heatExchangerWarranty: 4,
        tableData: [
            ["Apšildomas plotas", "iki m\u00b2", 160, 200, 250, 300, 400],
            ["Kuro įkrovimas", "l/dm\u00b3", 105, 125, 140, 160, 192],
            ["Pakuros gylis", "mm", 500, 500, 500, 500, 550],
            ["Kuro užkrovimo angos dydis", "cm", "29x23", "34x23", "39x23", "44x23", "44x23"],
            ["Šilumokaičio plotas", "m\u00b2", 3.0, 3.4, 3.8, 4.2, 4.8],
            ["Horizontalių šilumokaičių kiekis", "vnt", 3, 3,3,3,3],
            ["Vandens tūris katile", "l", 66,72,78,85,115],
            ["Masė", "kg", 230,260,290,320,350],
            ["Dūmtraukio skersmuo vidus/išorė", "mm", "150/160", "185/195", "185/195", "185/195", "185/195"],
        ],
        additionalData: [
            ["Minimali katilo darbo temperatūra", "60 \u2070C"],
            ["Maksimali katilo darbo temperatūra", "90 \u2070C"],
            ["Šildymo efektyvumas", "85%"],
            ["Hidraulinių jungčių matmenys", "G 1 \u00b9\u{141F}\u00b2 colių"],
            ["Maksimalus eksploatacinis slėgis", "1.5 bar"],
            ["Reikalaujama trauka dūmtraukyje", ">20 Pa"]
        ],
        selections: [
            [""],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1200, 1200, 1200, 1200, 1300],
            ["B", "mm", 420, 470, 520, 570, 570],
            ["C", "mm", 810, 810, 810, 810, 860],
        ],
        icons: ["nerud_01", "antrinio_02", "2deg_03", "3horiz_04", "dumnut_05", "labdidikr_06", "2traukreg_07", "krypt_08"],
        iconLocations: [[224, 380], [218, 328], [254, 320], [302, 154], [360, 183], [308, 254], [408, 435], [430, 240]],
        description: "Ilgus metus naudojama konstrukcija su dviguba degimo kamera ir trimis horizontaliais šilumokaičiais užtikrina geriausias katilo veikimo sąvybes, lengvą aptarnavimą ir ilgą tarnavimo laiką. Katilas labai tinkamas deginti malkas ir anglį. Didžiausias šilumokaičio plotas, įkrovos tūris užtikrina aukštą naudingumo koeficientą ir labai ilgą degimo laiką. Tai puikaus kainos ir kokybės santykio katilas.",
        dimensionsImage: "../assets/ekonMaxDimensions.svg",
        techSpecPdf: "../assets/ekon_max_lt.pdf",
        connectionSchemePdf: "../assets/ekonMaxPajungimasLT.pdf",
        certificatePdf: "../assets/EU atitikties deklaracijos EKON MAX.pdf",
        instructionsPdf: "../assets/ekon_max_instrukcija.pdf",
    }),
    new Katilas({
        id: 4,
        name: "Ekon",
        type: "Kieto kuro",
        imageUrl: "assets/ekon.png",
        pjuvis: "assets/ekon_pjuvis.png",
        feature: "Ekonomiškas",
        price: 1300,
        pricing:[
            [10, 1300, 0, [0]],
            [13, 1500, 0, [0]],
            [16, 1650, 0, [0]],
            [20, 1850, 0, [0]],
        ],
        minPower: 10,
        maxPower: 20,
        power: [10, 13, 16, 20],
        minArea: 100,
        maxArea: 200,
        efficiencyClass: "A+",
        fuelTypes: ["Malkos", "Briketai", "Pjuvenos", "Anglys"],
        heatExchangerWarranty: 4,
        tableData: [
            ["Apšildomas plotas", "iki m\u00b2", 100, 130, 160, 200],
            ["Kuro įkrovimas", "l/dm\u00b3", 65, 85, 100, 115],
            ["Pakuros gylis", "mm", 400, 400, 400, 400],
            ["Kuro užkrovimo angos dydis", "cm", "29x23", "29x23", "34x23", "39x23"],
            ["Šilumokaičio plotas", "m\u00b2", 1.9, 2.2, 2.6, 2.8],
            ["Horizontalių šilumokaičių kiekis", "vnt", 2,2,2,2],
            ["Vandens tūris katile", "l", 41, 52, 56, 68],
            ["Masė", "kg", 160, 190, 210, 250],
            ["Dūmtraukio skersmuo vidus/išorė", "mm", "150/160", "150/160", "150/160", "185/195"],
        ],
        additionalData: [
            ["Minimali katilo darbo temperatūra", "60 \u2070C"],
            ["Maksimali katilo darbo temperatūra", "90 \u2070C"],
            ["Šildymo efektyvumas", "83%"],
            ["Hidraulinių jungčių matmenys", "G 1 \u00b9\u{141F}\u2074 colių"],
            ["Maksimalus eksploatacinis slėgis", "1.5 bar"],
            ["Reikalaujama trauka dūmtraukyje", "15-20 Pa"]
        ],
        selections: [
            [""],
            [""],
        ],
        dimensions: [
            ["A", "mm", 910, 1100, 1100, 1150],
            ["B", "mm", 420, 420, 470, 520],
        ],
        icons: ["nerud_01", "antrinio_02", "2deg_03", "3horiz_04", "dumnut_05", "labdidikr_06", "2traukreg_07", "krypt_08"],
        iconLocations: [[224, 360], [230, 316], [258, 318], [304, 126], [360, 150], [320, 210], [405, 435], [420, 220]],
        description: "Ilgus metus naudojama konstrukcija su dviguba degimo kamera ir horizontaliais šilumokaičiais užtikrina geriausias katilo veikimo sąvybes, lengvą aptarnavimą ir ilgą tarnavimo laiką. Katilas labai tinkamas deginti malkas, briketus ir anglį. Didelis šilumokaičio plotas, įkrovos tūris užtikrina aukštą naudingumo koeficientą, ekonomišką degimą ir labai ilgą degimo laiką. Tai puikaus kainos ir kokybės santykio katilas.",
        dimensionsImage: "../assets/ekonDimensions.svg",
        techSpecPdf: "../assets/ekon_lt.pdf",
        connectionSchemePdf: "../assets/ekonPajungimasLT.pdf",
        certificatePdf: "../assets/EU atitikties deklaracijos EKON.pdf",
        instructionsPdf: "../assets/ekon_instrukcija.pdf",
    }),
    new Katilas({
        id: 5,
        name: "Klasika",
        type: "Kieto kuro",
        imageUrl: "assets/klasika.png",
        pjuvis: "assets/klasika_pjuvis.png",
        feature: "Paprastas naudoti",
        price: 1050,
        pricing:[
            [8, 1050, 0, [0]],
            [10, 1150, 0, [0]],
            [13, 1300, 0, [0]],
            [16, 1500, 0, [0]],
            [20, 1650, 0, [0]],
            [25, 1800, 0, [0]],
            [30, 1950, 0, [0]],
        ],
        minPower: 8,
        maxPower: 30,
        power: [8, 10, 13, 16, 20, 25, 30],
        minArea: 80,
        maxArea: 300,
        efficiencyClass: "A+",
        fuelTypes: ["Malkos", "Briketai", "Anglys"],
        heatExchangerWarranty: 4,
        tableData: [
            ["Apšildomas plotas", "iki m\u00b2", 80, 100, 130, 160, 200, 250, 300],
            ["Kuro įkrovimas", "l/dm\u00b3", 60, 72, 90, 105, 125, 140, 160],
            ["Pakuros gylis", "mm", 400, 400, 500, 500, 500, 500, 500],
            ["Kuro užkrovimo angos dydis", "cm", "24x23", "29x23", "29x23", "29x23", "34x23", "39x23", "44x23"],
            ["Šilumokaičio plotas", "m\u00b2", 1.3, 1.5, 1.8, 2.3, 2.5, 2.8, 3.1],
            ["Horizontalių šilumokaičių kiekis", "vnt", 2,2,2,3,3,3,3],
            ["Vandens tūris katile", "l", 38,42,50,59,63,58,72],
            ["Masė", "kg", 135,150,170,200,225,250,275],
            ["Dūmtraukio skersmuo vidus/išorė", "mm", "150/160", "150/160", "150/160", "150/160", "185/195", "185/195", "185/195"],
            ["Hidraulinių jungčių matmenys", "colių", "G 1 \u00b9\u{141F}\u2074", "G 1 \u00b9\u{141F}\u2074","G 1 \u00b9\u{141F}\u2074","G 1 \u00b9\u{141F}\u00b2","G 1 \u00b9\u{141F}\u00b2","G 1 \u00b9\u{141F}\u00b2","G 1 \u00b9\u{141F}\u00b2"]
        ],
        additionalData: [
            ["Minimali katilo darbo temperatūra", "60 \u2070C"],
            ["Maksimali katilo darbo temperatūra", "90 \u2070C"],
            ["Šildymo efektyvumas", "83%"],
            ["Maksimalus eksploatacinis slėgis", "1.5 bar"],
            ["Reikalaujama trauka dūmtrukyje", "15-20 Pa"]
        ],
        selections: [
            [""],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1000, 1000, 1000, 1200, 1200, 1200, 1200],
            ["B", "mm", 370, 420, 420, 420, 470, 520, 570],
            ["C", "mm", 570, 570, 670, 670, 670, 670, 670],
        ],
        icons: ["didplot_01", "dumnut_02", "labdidikr_03", "2traukreg_04", "krypt_05"],
        iconLocations: [[296, 146], [350, 150], [296, 250], [390, 440], [400, 148]],
        description: "Seniausiai naudojama klasikinė katilo konstrukcija, su dideliu įkrovos tūriu, dideliu šilumokaičiu. Tai užtikrina labai ilgą degimo laiką. Katilas labai tinkamas kūrenti malkas, drėgnesnį kietą kurą. Tai nebrangus, patikimas, paprastas ir laiko patikrintas katilas.",
        dimensionsImage: "../assets/klasikaDimensions.svg",
        techSpecPdf: "../assets/klasika_lt.pdf",
        connectionSchemePdf: "../assets/klasikaPajungimasLT.pdf",
        certificatePdf: "../assets/EU atitikties deklaracijos KLASIKA.pdf",
        instructionsPdf: "../assets/klasika_instrukcija.pdf",
    }),
    new Katilas({
        id: 6,
        name: "Kompakt",
        type: "Kieto kuro",
        imageUrl: "assets/kompakt.png",
        pjuvis: "assets/kompakt_pjuvis.png",
        feature: "Nebrangus",
        price: 1100,
        pricing:[
            [12, 1100, 0, [0]],
            [16, 1250, 0, [0]],
            [20, 1400, 0, [0]],
        ],
        minPower: 12,
        maxPower: 20,
        power: [12, 16, 20],
        minArea: 120,
        maxArea: 200,
        efficiencyClass: "A+",
        fuelTypes: ["Malkos", "Briketai", "Anglys"],
        heatExchangerWarranty: 4,
        tableData: [
            ["Apšildomas plotas", "iki m\u00b2", 120, 160, 200],
            ["Kuro įkrovimas", "l/dm\u00b3", 72, 90, 105],
            ["Pakuros gylis", "mm", 400, 500, 500],
            ["Kuro užkrovimo angos dydis", "cm", "29x23", "29x23", "29x23"],
            ["Horizontalių šilumokaičių kiekis", "vnt", 2,2,3],
            ["Vandens tūris katile", "l", 42, 50, 59],
            ["Masė", "kg", 150, 170, 200],
            ["Hidraulinių jungčių matmenys", "coliai", "G 1 \u00b9\u{141F}\u2074" , "G 1 \u00b9\u{141F}\u2074" , "G 1 \u00b9\u{141F}\u00b2"],
        ],
        additionalData: [
            ["Dūmtraukio skersmuo vidus/išorė", "150/160 mm"],
            ["Minimali katilo darbo temperatūra", "60 \u2070C"],
            ["Maksimali katilo darbo temperatūra", "90 \u2070C"],
            ["Šildymo efektyvumas", "83%"],
            ["Maksimalus eksploatacinis slėgis", "1.5 bar"],
            ["Reikalaujama trauka dūmtrukyje", ">15 Pa"]
        ],
        selections: [
            [""],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1000,1000,1200],
            ["B", "mm", 570,670,670],
        ],
        icons: ["didplot_01", "dumnut_02", "labdidikr_03", "2traukreg_04", "krypt_05"],
        iconLocations: [[296, 146], [350, 150], [296, 250], [390, 440], [400, 148]],
        description: "Ilgaamžės ir patikimos konstrukcijos kieto kuro katilas, su dideliu šilumokaičiu ir didele kuro įkrova. Jis pasižymi lengva priežiūra ir eksploatacija, ilgu degimo laiku. Katilo valymą palengvina atskiros durelės. Kompaktiška konstrukcija leidžia katilą įrengti mažose patalpose. Dūmų nutraukimo sklendė skirta lengvesniam įkūrimui, bei sumažina dūmų patekimo į patalpą galimybę pakraunant kurą. Katilas komplektuojamas su dviguba traukos reguliavimo sklende, tvirtomis, lengvai atidaromomis durelėmis, ilgaamžiais ketaus ardeliais, kurie užtikrina geresnį kuro sudeginimą.",
        dimensionsImage: "../assets/kompaktDimensions.svg",
        techSpecPdf: "../assets/kompakt_lt.pdf",
        connectionSchemePdf: "../assets/kompaktPajungimasLT.pdf",
        certificatePdf: "../assets/EU atitikties deklaracijos KOMPAKT.pdf",
        instructionsPdf: "../assets/kompakt_instrukcija.pdf",
    }),
    new Katilas({
        id: 7,
        name: "Ignis",
        type: "Kieto kuro",
        imageUrl: "assets/ignis.png",
        pjuvis: "assets/ignis_pjuvis.png",
        feature: "Praktiškas",
        price: 1050,
        pricing:[
            [10, 1050, 0, [0]],
            [15, 1350, 0, [0]],
        ],
        minPower: 10,
        maxPower: 15,
        power: ["M 10", "D 15"],
        minArea: 100,
        maxArea: 150,
        efficiencyClass: "A+",
        fuelTypes: ["Malkos", "Briketai", "Anglys"],
        heatExchangerWarranty: 4,
        tableData: [
            ["Apšildomas plotas", "iki m\u00b2", 100, 150],
            ["Kuro įkrovimas", "l/dm\u00b3", 70, 90],
            ["Pakuros gylis", "mm", 300, 400],
            ["Kuro užkrovimo angos dydis", "cm", "34x26", "34x26"],
            ["Šilumokaičio plotas", "m\u00b2", 1.2, 1.8],
            ["Vertikalių šilumokaičių kiekis", "vnt", 1, 2],
            ["Vandens tūris katile", "l", 32, 42],
            ["Masė", "kg", 130, 190],
            ["Šildymo efektyvumas", "%", 76, 78],
            ["Dūmtraukio skersmuo vidus/išorė", "mm", "130/140", "150/160"]
        ],
        additionalData: [
            ["Hidraulinių jungčių matmenys", "G 1 \u00b9\u{141F}\u2074 colių"],
            ["Minimali katilo darbo temperatūra", "60 \u2070C"],
            ["Maksimali katilo darbo temperatūra", "90 \u2070C"],
            ["Maksimalus eksploatacinis slėgis", "1.5 bar"],
            ["Reikalaujama trauka dūmtraukyje", "15-20 Pa"], 
        ],
        selections: [
            [""],
            [""],
        ],
        icons: ["didplot_01", "viettrauk_02", "labdidikr_03", "vasziem_04", "krypt_05"],
        iconLocations: [[233, 190], [434, 260], [350, 220], [290, 240], [430, 204]],
        description: "Katilas-viryklė, tradicinis, laiko patikrintas gaminys. Katilas turi labai didelę malkų įkrovimo talpą ir didelį šilumokaitį. Konstrukcija užtikrina aukštą katilo efektyvumą ir ilgą degimo laiką. Naudojamas aukštos kokybės, sertifikuotas 5 milimetrų storio plieno lakštas užtikrina ilgą tarnavimo laiką. Nepriekaištinga kokybė ir patikima konstrukcija užtikrina gaminiui 4 metų garantiją. Katilą galima naudoti vasaros ir žiemos režimais. Yra galimybė sumontuoti automatinį traukos reguliatorių. Katilas komplektuojamas su kokybiška ketine kaitlente, keičiamos krypties durelėmis, patogiu mechaniniu traukos reguliatoriumi.",
        dimensionsImage: "../assets/ignisDimensions.svg",
        techSpecPdf: "../assets/ignis_lt.pdf",
        connectionSchemePdf: "../assets/ignisPajungimasLT.pdf",
        certificatePdf: "../assets/EU atitikties deklaracijos IGNIS.pdf",
        instructionsPdf: "../assets/ignis_instrukcija.pdf",
    }),
    new Katilas({
        id: 8,
        name: "Agro Uni",
        type: "Žemės ūkio atliekų",
        imageUrl: "assets/agro_uni.png",
        pjuvis: "assets/agro_uni_pjuvis.png",
        feature: "Ekologiškas",
        price: 2750,
        pricing:[
            [15, 2750, 0, [0,250]],
            [20, 3100, 0, [0,250]],
            [30, 3500, 0, [0,350]],
            [40, 4000, 0, [0,350]],
        ],
        minPower: 15,
        maxPower: 40,
        power: [15, 20, 30, 40],
        minArea: 150,
        maxArea: 400,
        efficiencyClass: "A+",
        fuelTypes: ["Grūdai", "Atsijos", "Granulės", "Skiedros", "Anglys 0-50", "Malkos"],
        heatExchangerWarranty: 5,
        burnerWarranty: 3,
        tableData: [
            ["Apšildomas plotas", "iki m\u00b2", 150, 200, 300, 400],
            ["Pakuros gylis", "mm", 450, 450, 500, 600],
            ["Kuro įkrovimas", "l/dm\u00b3", 35, 40, 65, 104],
            ["„Zenono“ degiklis", "kW", "3-15", "3-20", "4-30", "8-50"],
            ["Šilumokaičio plotas", "m\u00b2", 1.9, 2.5, 3.3, 4.2],
            ["Kuro užkrovimo angos dydis", "cm", "29x23", "34x23", "44x23", "49x23"],
            ["Horizontalių šilumokaičių kiekis", "vnt", 3, 4, 4, 4],
            ["Vandens tūris katile", "l", 48, 62, 69, 100],
            ["Masė", "kg", 180, 230, 270, 310],
            ["Dūmtraukio skersmuo vidus/išorė", "mm", "150/160", "150/160", "150/160", "185/195"],
        ],
        additionalData: [
            ["Kuro talpa su maišykle", "400/600 l/dm\u00b3"],
            ["Minimali katilo darbo temperatūra", "60 \u2070C"],
            ["Maksimali katilo darbo temperatūra", "90 \u2070C"],
            ["Šildymo efektyvumas", "90%"],
            ["Hidraulinių jungčių matmenys", "G 1 \u00b9\u{141F}\u2074 colių"],
            ["Maksimalus eksploatacinis slėgis", "1.5 bar"],
            ["Reikalaujama trauka dūmtraukyje", "15-20 Pa"]
        ],
        selections: [
            ["Kuro talpa", "400 l", "600 l"],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1100, 1200, 1200, 1280],
            ["B", "mm", 420, 470, 570, 620],
            ["C", "mm", 670, 670, 700, 800],
        ],
        degiklis: "„Zenono“",
        degiklisData: [
            ["Galia", "15-50 kW"],
            // ["Degimo temperatūra", "850 \u2070C"],
            // ["Dydis", "890 x 380 x 450 mm"],
            // ["Jungtis", "D90 mm"],
        ],
        icons: ["zen_01", "nerud_02", "4vert_03", "tubul_04", "2deg_05", "talppam_06", "krypt_07"],
        iconLocations: [[240, 350], [240, 350], [240, 190], [240, 190], [235, 320], [410, 316], [320, 210]],
        description: "Ypač universalus žemės ūkio atliekų katilas, galintis automatiniu režimu deginti įvairias grūdines kultūras, žemės ūkio atliekas, prastos kokybės granules, skiedras, pjuvenas ir anglį, malkas ir kitą kietą kurą, kuris yra pakraunamas per vidurines dureles. Specialus kuro talpos pamaišymo mechanizmas, savaime išsivalantis degiklis, didelė pelenų dėžė ir kiti sprendimai užtikrina sklandų įvairių kuro rūšių deginimą ir katilo veikimą. Didelis horizontalus keturių vertimų šilumokaitis užtikrina patogų valymą ir aukštą katilo naudingumo koeficientą. Katilas yra komplektuojamas su „Zenono“ degikliu. Nerekomenduojame katilo komplektuoti su nerūdijančio plieno dumtraukiu.",
        burnerDescription: "Specializuotas degiklis, galintis automatiniu režimu deginti įvairias grūdines kultūras, žemės ūkio produkcijos atliekas, prastos kokybės granules ir įvairią anglį. Jame įmontuotas nerūdijančio plieno valymo mechanizmas, kuris puikiai pašalina degimo metu susidariusį šlaką.",
        burnerImage: "../assets/zenonoDegiklis.png",
        dimensionsImage: "../assets/agroUniDimensions.svg",
        certificatePdf: "../assets/EU atitikties deklaracijos AGRO UNI.pdf",
        instructionsPdf: "../assets/agro_uni_instrukcija.pdf",
        techSpecPdf: "../assets/agro_uni_lt.pdf",
        controllerInstructionsPdf: "../assets/IE-70 naudojimo instrukcija N.pdf",
        connectionSchemePdf: "../assets/agroUniPajungimasLT.pdf",
    }),
    new Katilas({
        id: 9,
        name: "Universa",
        type: ["Granulinio kuro", "Žemės ūkio atliekų"],
        imageUrl: "assets/universa.png",
        pjuvis: "assets/universa_pjuvis.png",
        feature: "Populiarus",
        price: 2550,
        pricing:[
            [15, 2550, 0, [0, 50]],
            [20, 2900, 0, [0, 50]],
            [30, 3300, 0, [0, 50]],
            [40, 3850, 0, [0, 50]],
        ],
        minPower: 15,
        maxPower: 40,
        power: [15,20,30,40],
        minArea: 150,
        maxArea: 400,
        efficiencyClass: "A+",
        fuelTypes: ["Granulės", "Grūdai", "Durpės", "Šiaudų granulės", "Saulėgražų granulės", "Anglys 0-50"],
        heatExchangerWarranty: 5,
        burnerWarranty: 3,
        tableData: [
            ["Apšildomas plotas", "iki m\u00b2", 150,200,300,400],
            ["Pakuros gylis", "mm", 450,450,500,600],
            ["Kuro įkrovimas", "l/dm\u00b3", 35,40,65,104],
            ["„Zenono“ degiklis", "kW", "3-15", "3-20", "4-30", "8-50"],
            ["Šilumokaičio plotas", "m\u00b2", 1.9,2.5,3.3,4.2],
            ["Kuro užkrovimo angos dydis", "cm", "29x23", "34x23", "44x23", "49x23"],
            ["Horizontalių šilumokaičių kiekis", "vnt", 3, 4, 4,4],
            ["Vandens tūris katile", "l", 48,62,69,100],
            ["Masė", "kg", 180,230,270,320],
            ["Dūmtraukio skersmuo vidus/išorė", "mm", "150/160","150/160","150/160","185/195"],
        ],
        additionalData: [
            ["Kuro talpa", "230/300 l/dm\u00b3"],
            ["Minimali katilo darbo temperatūra", "60 \u2070C"],
            ["Maksimali katilo darbo temperatūra", "90 \u2070C"],
            ["Šildymo efektyvumas", "90%"],
            ["Dūmtraukio skersmuo vidus/išorė", "150/160 mm"],
            ["Hidraulinių jungčių matmenys", "G 1 \u00b9\u{141F}\u2074 colių"],
            ["Maksimalus eksploatacinis slėgis", "1.5 bar"],
            ["Reikalaujama trauka dūmtraukyje", "15-20 Pa"]
        ],
        selections: [
            ["Kuro talpa", "200 l", "300 l"],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1100, 1200, 1200, 1280],
            ["B", "mm", 420, 470, 570, 620],
            ["C", "mm", 670, 670, 700, 800],
        ],
        degiklis: "„Zenono“",
        degiklisData: [
            ["Galia", "15-50 kW"],
            // ["Degimo temperatūra", "850 \u2070C"],
            // ["Dydis", "890 x 380 x 450 mm"],
            // ["Jungtis", "D90 mm"],
        ],
        icons: ["zen_01", "nerud_02", "4vert_03", "tubul_04", "2deg_05", "krypt_06"],
        iconLocations: [[240, 350], [240, 350], [246, 192], [246, 192], [234, 320], [330, 200]],
        description: "Ypač universalus granulinio kuro katilas, veikiantis automatiniu režimu ir skirtas deginti tiek geros, tiek prastos kokybės granules, taip pat visas grūdines kultūras, birias žemės ūkio atliekas, durpes ir anglį, taip pat malkas ar kitą kietą kurą, kuris yra pakraunamas per vidurines dureles. Nebrangus, patikimas ir universalus granulinio kuro katilas, kurio konstrukcija specialiai pritaikyta įvairaus kuro deginimui. Didelis horizontalus keturių vertimų šilumokaitis užtikrina patogų valymą ir aukštą naudingumo koeficientą. Katilas yra komplektuojamas su „Zenono“ degikliu.",
        burnerDescription: "Specializuotas degiklis, galintis automatiniu režimu deginti įvairias grūdines kultūras, žemės ūkio produkcijos atliekas, prastos kokybės granules ir įvairią anglį. Jame įmontuotas nerūdijančio plieno valymo mechanizmas, kuris puikiai pašalina degimo metu susidariusį šlaką.",
        burnerImage: "../assets/zenonoDegiklis.png",
        dimensionsImage: "../assets/universaDimensions.svg",
        talpa: ['UT 200', 'UT 300'],
        talpaData:[
            ["A1", "mm", 1200, 1300],
            ["B1", "mm", 600, 750]
        ],
        certificatePdf: "../assets/EU atitikties deklaracijos UNIVERSA.pdf",
        controllerInstructionsPdf: "../assets/IE-70 naudojimo instrukcija N.pdf",
        techSpecPdf: "../assets/universa_lt.pdf",
        connectionSchemePdf: "../assets/universaPajungimasLT.pdf",
        instructionsPdf: "../assets/universa_instrukcija.pdf",
    }),
    new Katilas({
        id: 10,
        name: "Pele Max",
        type: "Pramoninis",
        imageUrl: "assets/pelle_max.png",
        pjuvis: "assets/pelle_max_pjuvis.png",
        feature: "Ekonomiškas",
        price: 6140,
        pricing:[
            [50, 6140, 460, [0, 100, 300]],
            [70, 7600, 900, [0, 100, 300]],
            [100, 10000, 700, [0, 100, 300]],
        ],
        minPower: 50,
        maxPower: 100,
        power: [50,70,100],
        minArea: 500,
        maxArea: 1000,
        efficiencyClass: "A+",
        fuelTypes: ["Malkos", "Briketai", "Anglys", "Granulės"],
        heatExchangerWarranty: 5,
        burnerWarranty: 2,
        tableData: [
            ["Apšildomas plotas", "iki m\u00b2", 500,700,1000],
            ["Pakuros gylis", "mm", 680,680,980],
            ["Kuro įkrovimas", "l/dm\u00b3", 105,145,280],
            ["Rotacinis granulių degiklis", "kW", "10-50", "15-70", "20-100"],
            ["Šilumokaičio plotas", "m\u00b2", 6, 7.8, 11.8],
            ["Kuro užkrovimo angos dydis", "cm", "50x26", "60x26", "70x26"],
            ["Horizontalių šilumokaičių kiekis", "vnt", 5,5,5],
            ["Vandens tūris katile", "l", 142,180,280],
            ["Masė", "kg", 500,650,890],
            ["Dūmtraukio skersmuo vidus/išorė", "mm", "185/195", "185/195", "200/210"],
        ],
        additionalData: [
            ["Kuro talpa", "500/700/1000 l/dm\u00b3"],
            ["Minimali katilo darbo temperatūra", "60 \u2070C"],
            ["Maksimali katilo darbo temperatūra", "90 \u2070C"],
            ["Šildymo efektyvumas", "90%"],
            ["Hidraulinių jungčių matmenys", "G 2 colių"],
            ["Maksimalus eksploatacinis slėgis", "1.5 bar"],
            ["Reikalaujama trauka dūmtraukyje", ">20 Pa"]
        ],
        selections: [
            ["Kuro talpa", "500 l", "700 l", "1000 l"],
            [
                ["Degiklis"],
                ["50", "8-36 kW", "10-50 kW"],
                ["70", "10-50 kW", "15-70 kW"],
                ["100", "15-70 kW", "20-100 kW"],
            ]
        ],
        dimensions: [
            ["A", "mm", 1585, 1585, 1735],
            ["A1", "mm", 1275, 1355, 1430],
            ["B", "mm", 630, 730, 830],
            ["B1", "mm", 650, 750, 850],
            ["C", "mm", 890, 890, 1195],
            ["C1", "mm", 265, 340, 340],
        ],
        degiklis: "„Kipi Rot Power“",
        degiklisData: [
            ["Galia", "36-100 kW"],
            // ["Degimo temperatūra", "850 \u2070C"],
            // ["Dydis", "890 x 380 x 450 mm"],
            // ["Jungtis", "D90 mm"],
        ],
        icons: ["56silum_01", "2deg_02", "rotac_03", "labdidikr_04", "krypt_05"],
        iconLocations: [[242, 162], [252, 320], [310, 380], [235, 286], [370, 190]],
        description: "Pramoninio naudojimo universalus granulinis katilas su moderniu ir kokybišku rotaciniu degikliu, galinčiu deginti ir prastesnės kokybės granules. Tai universalus katilas, kuriuo galima kūrenti tiek graules pilnai automatiniu režimu, tiek įvairų kietą kurą, įkraunamą rankomis. Katilas turi vieną didžiausių rinkoje šilumokaičio plotą. Penki horizontalūs šilumokaičiai, daug vertimų bei ilgas dūmų kelias užtikrina labai aukštą katilo naudingumo koeficientą.",
        burnerDescription: "Pilnai automatinis granulinis degiklis, skirtas deginti geros ir vidutinės kokybės granules. Degiklis turi automatinio valymo mechanizmą – rotacinę besisukančią degimo ir oro padavimo kamerą, kurios pagalba degiklis pats išsivalo nuo pelenų ir šlako.",
        burnerImage: "../assets/kipiRotPower.png",
        burnerInstructionsPdf: "../assets/pele_max_degiklio_instrukcija.pdf",
        dimensionsImage: "../assets/peleMaxDimensions.svg",
        certificatePdf: "../assets/EU atitikties deklaracijos PELE MAX.pdf",
        instructionsPdf: "../assets/pele_max_instrukcija.pdf",
        controllerInstructionsPdf: "../assets/Valdiklio Plum 920 instrukcija.pdf",
        techSpecPdf: "../assets/pele_max_lt.pdf",
        connectionSchemePdf: "../assets/peleMaxPajungimasLT.pdf",
    }),
    new Katilas({
        id: 11,
        name: "Agro Max",
        type: ["Žemės ūkio atliekų", "Pramoninis"],
        imageUrl: "assets/agro_max.png",
        pjuvis: "assets/agro_max_pjuvis.png",
        feature: "Universalus",
        price: 5700,
        pricing:[
            [50, 5700, 0, [0,200,400]],
            [70, 6900, 0, [0,200,400]],
            [100, 8500, 300, [0,200,400]],
        ],
        minPower: 50,
        maxPower: 100,
        power: [50, 70, 100],
        minArea: 500,
        maxArea: 1000,
        efficiencyClass: "A",
        fuelTypes: ["Grūdai", "Atsijos", "Granulės", "Skiedros", "Anglys 0-50", "Malkos"],
        heatExchangerWarranty: 5,
        burnerWarranty: 2,
        tableData: [
            ["Apšildomas plotas", "iki m\u00b2", 500, 700, 1000],
            ["Pakuros gylis", "mm", 680, 680, 980],
            ["Kuro įkrovimas", "l/dm\u00b3", 140, 170, 290],
            ["„Zenono“ degiklis", "kw", "15-70", "15-70", "20-100"],
            ["Šilumokaičio plotas", "m\u00b2", 5.9, 7.1, 11],
            ["Kuro užkrovimo angos dydis", "cm", "50x34", "60x34", "70x34"],
            ["Horizontalių šilumokaičių kiekis", "vnt", 5, 5, 5],
            ["Vandens tūris katile", "l", 145, 165, 280],
            ["Masė", "kg", 550, 650, 930],
            ["Dūmtraukio skersmuo vidus/išorė", "mm", "185/195", "185/195", "200/210"],
            ["Kuro talpa", "l/dm\u00b3", 400, 600, 800]
        ],
        additionalData: [
            ["Minimali katilo darbo temperatūra", "60 \u2070C"],
            ["Maksimali katilo darbo temperatūra", "90 \u2070C"],
            ["Šildymo efektyvumas", "90%"],
            ["Hidraulinių jungčių matmenys", "G2 coliai"],
            ["Maksimalus eksploatacinis slėgis", "1.5 bar"],
            ["Reikalaujama trauka dūmtraukyje", ">20 Pa"],
        ],
        selections: [
            ["Kuro talpa", "400 l", "600 l", "800 l"],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1590, 1590, 1740],
            ["B", "mm", 630, 730, 830],
            ["C", "mm", 890, 890, 1190],
        ],
        degiklis: "„Zenono“",
        degiklisData: [
            ["Galia", "50-100 kW"],
            // ["Degimo temperatūra", "850 \u2070C"],
            // ["Dydis", "890 x 380 x 450 mm"],
            // ["Jungtis", "D90 mm"],
        ],
        icons: ["zen_01", "nerud_02", "talppam_03", "2deg_04", "5silum_05", "krypt_06"],
        iconLocations: [[270, 350], [270, 350], [450, 306], [274, 306], [280, 162], [380, 274]],
        description: "Tai ypač universalus pramoninio naudojimo katilas, skirtas automatiniu režimu deginti įvairias grūdines kultūras, žemės ūkio atliekas, prastos kokybės granules ir įvairią anglį. Tai aukščiausios kategorijos ir techninio lygio gaminys, specialiai sukonstruotas tokio kuro deginimui. Didelė talpa su pamaišymo mechanizmu, savaime išsivalantis degiklis, didelė pelenų dėžė ir kiti privalumai užtikrina lengvą katilo naudojimą. Didelis horizontalus penkių vertimų šilumokaitis užtikrina patogų valymą ir aukštą naudingumo koeficientą.",
        burnerDescription: "Specializuotas degiklis, galintis automatiniu režimu deginti įvairias grūdines kultūras, žemės ūkio produkcijos atliekas, prastos kokybės granules ir įvairią anglį. Jame įmontuotas nerūdijančio plieno valymo mechanizmas, kuris puikiai pašalina degimo metu susidariusį šlaką.",
        burnerImage: "../assets/zenonoDegiklis.png",
        dimensionsImage: "../assets/agroMaxDimensions.svg",
        talpa: ['AT 400', 'AT 600', 'AT 800'],
        talpaData:[
            ["A1", "mm", 1500, 1700, 1700],
            ["B1", "mm", 1080, 1080, 1190]
        ],
        certificatePdf: "../assets/EU atitikties deklaracijos AGRO MAX.pdf",
        instructionsPdf: "../assets/agro_max_instrukcija.pdf",
        techSpecPdf: "../assets/agro_max_lt.pdf",
        controllerInstructionsPdf: "../assets/IE-70 naudojimo instrukcija N.pdf",
        connectionSchemePdf: "../assets/agroMaxPajungimasLT.pdf",
    }),
    new Katilas({
        id: 12,
        name: "Bio Max",
        type: "Pramoninis",
        imageUrl: "assets/bio_max.png",
        pjuvis: "assets/bio_max_pjuvis.png",
        feature: "Praktiškas",
        price: 3500,
        pricing:[
            [50, 3500, 0, [0]],
            [70, 4500, 0, [0]],
            [100, 5800, 0, [0]],
        ],
        minPower: 50,
        maxPower: 100,
        power: [50, 70, 100],
        minArea: 500,
        maxArea: 1000,
        efficiencyClass: "A+",
        fuelTypes: ["Malkos", "Briketai", "Anglys"],
        heatExchangerWarranty: 4,
        tableData: [
            ["Apšildomas plotas", "iki m\u00b2", 500, 700, 1000],
            ["Kuro įkrovimas", "l/dm\u00b3", 240, 380, 550],
            ["Pakuros gylis", "mm", 600, 800, 1000], 
            ["Kuro užkrovimo angos dydis", "cm", "40x50", "40x60", "40x70"],
            ["Šilumokaičio plotas", "m\u00b2", 5, 7.1, 10],
            ["Horizontalių šilumokaičių kiekis", "vnt", 4, 4, 4],
            ["Vandens tūris katile", "l", 130, 180, 235],
            ["Masė", "kg", 420, 570, 800],
            ["Dūmtraukio skersmuo vidus/išorė", "mm", "200/210", "220/230", "250/260"],
        ],
        additionalData: [
            ["Hidraulinių jungčių matmenys", "G 2 coliai"],
            ["Minimali katilo darbo temperatūra", "60 \u2070C"],
            ["Maksimali katilo darbo temperatūra", "90 \u2070C"],
            ["Šildymo efektyvumas", "83%"],
            ["Maksimalus eksploatacinis slėgis", "1.5 bar"],
            ["Reikalaujama trauka dūmtraukyje", ">20 Pa"],
        ],
        selections: [
            [""],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1600, 1600, 1600],
            ["B", "mm", 630, 730, 830],
            ["C", "mm", 800, 1000, 1200],
        ],
        icons: ["didplot_01", "labdidikr_02", "2traukreg_03", "dumnut_04", "krypt_05"],
        iconLocations: [[312, 152], [300, 280], [414, 440], [370, 150], [440, 280]],
        description: "Tai klasikinės konstrukcijos, patikimas ir paprastas naudoti pramoninis katilas. Jis taip pat pasižymi labai didele kuro įkrova, dideliu šilumokaičiu, ilgu tarnavimo laiku ir žema kaina. Katilo įkrovos tūris ir šilumokaičio plotas vieni didžiausių rinkoje. Tai užtikrina labai ilgą degimo laiką. Katilas labai tinkamas deginti malkas, drėgnesnį kurą.",
        dimensionsImage: "../assets/bioMaxDimensions.svg",
        certificatePdf: "../assets/EU atitikties deklaracijos BIO MAX.pdf",
        techSpecPdf: "../assets/bio_max_lt.pdf",
        connectionSchemePdf: "../assets/bioMaxPajungimasLT.pdf",
        instructionsPdf: "../assets/bio_max_instrukcija.pdf",
    }),
];