import React from 'react';
import UsefulInfo from "../footer/usefulInfo";
import Location from "../footer/location";
import HeaderBigBlack from '../header/headerBigBlack';
import HeaderBlack from '../header/headerBlack';
import { Link, useNavigate } from "react-router-dom";
import { useLanguage } from '../LanguageContext';

function Katiluparama() {

    let [language, languageTexts, languageKatilai, toggleLanguage] = useLanguage();
    const navigate = useNavigate()
    const translation = languageTexts;

  return <>

    <HeaderBigBlack activeTab={""}/>
    <div className='smallInfoHeader'><HeaderBlack activeTab={"Katiluparama"}/></div>

    <div className='informationBody'>
        <div className='information1'>
            <div className='pajungimas'>
                <h1>LEA Parama katilų keitimui</h1>
                <p>
                Lietuvos energetikos agentūra (LEA) teikia paramą neefektyvių katilų keitimui į naujus.
                Informaciją apie paramą galite rasti agentūros svetainėje <a className='EnaLink' href='https://www.ena.lt/inpa-katilokeita/'>ena.lt </a></p>
                <p className='katiluParamP'>
                Kvietimai teikti paraiškas dėl paramos skelbiami kiekvieno metų ketvirčio pirmąją darbo dieną.
                </p>
            </div>
        </div>
    </div>
    <footer>
        <div className="infoFooter"><UsefulInfo/></div>
        <div className="locationFooter"><Location/></div>
    </footer>
  </>;
}

export default Katiluparama;