import React from 'react';
import UsefulInfo from "../footer/usefulInfo";
import Location from "../footer/location";
import HeaderBigBlack from '../header/headerBigBlack';
import HeaderBlack from '../header/headerBlack';
import { Link, useNavigate } from "react-router-dom";
import { useLanguage } from '../LanguageContext';

function Esparam() {

    let [language, languageTexts, languageKatilai, toggleLanguage] = useLanguage();
    const navigate = useNavigate()
    const translation = languageTexts;

  return <>

    <HeaderBigBlack activeTab={"Esparam"}/>
    <div className='smallInfoHeader'><HeaderBlack activeTab={"Esparam"}/></div>

    <div className='informationBody'>
        <div className='information1'>
            <div className='pajungimas'>
                <h1>ES parama</h1>
                <p className='esParamP1'>
                    Galimybė gauti paramą iki 595,78 Eur iš jungtinio projekto „Privačių elektromobilių įkrovimo prieigų įrengimas“ 
                    pateikus paraišką Lietuvos energetikos agentūrai pagal paskelbtą kvietimą „Juridinių asmenų privačių elektromobilių įkrovimo prieigų įrengimas 
                    darbovietėse“ paskatino „UAB Aukštaitijos katilai“ suplanuoti įsirengti elektromobilio įkrovimo prieigą.
                </p>
                <p className='esParamP2'>
                    Jungtinio projekto projektas įgyvendinamas pagal ekonomikos gaivinimo ir atsparumo didinimo planą 
                    <b> „Naujos kartos Lietuva“</b>, 
                    finansuojamą <b>Europos Sąjungos</b> ekonomikos gaivinimo ir atsparumo didinimo priemonės <b>„NextGenerationEU“</b> lėšomis.
                </p>
                <div className='esParamImgs'>
                    <img src='../assets/es1.png' alt='Finansuoja Europos Sąjunga - NextGenerationEU'></img>
                    <img src='../assets/es2.png' alt='Naujos kartos Lietuva'></img>
                    <img src='../assets/es3.png' alt='Lietuvos energetikos agentūra'></img>
                </div>
            </div>
        </div>

    </div>
    <footer>
        <div className="infoFooter"><UsefulInfo/></div>
        <div className="locationFooter"><Location/></div>
    </footer>
  </>;
}

export default Esparam;