import { Katilas } from "./Katilas";

export const MOCK_KATILAI_EN = [
     new Katilas({
         id: 1,
         name: "Pellet Uni",
         type: "Pellet fuel",
         imageUrl: "assets/pellet_uni.png",
         pjuvis: "assets/pellet_uni_pjuvis.png",
         feature: "Top Class",
         price: 3400,
         pricing: [
             [12, 3400, 0, [0, 30, 80, 110]],
             [16, 3530, 0, [0, 30, 80, 110]],
             [20, 3650, 120, [0, 30, 80, 110]],
             [26, 4070, 160, [0, 30, 80, 110]],
             [36, 4330, 160, [0, 30, 80, 110]],
         ],
         minPower: 12,
         maxPower: 36,
         power: [12, 16, 20, 26, 36],
         minArea: 120,
         maxArea: 360,
         efficiencyClass: "A++",
         fuelTypes: ["Pellets", "Firewood", "Briquettes", "Coal"],
         heatExchangerWarranty: 6,
         certificate: 5,
         tableData: [
             ["Heated area", "up to m\u00b2", 120, 160, 200, 260, 360],
             ["Fuel depth", "mm", 455, 455, 455, 505, 505],
             ["Fuel loading", "l/dm\u00b3", 55, 55, 65, 84, 93],
             ["Fuel capacity", "l/dm\u00b3", 230, 230, 250, 270, 270],
             ["Rotary pellet burner", "kW", "4-16", "4-16", "5-20", "6-26", "8-36"],
             ["Heat exchanger area", "m\u00b2", 2.2, 2.6, 2.9, 3.4, 3.7],
             ["Fuel loading hole size", "cm", "29x23", "29x23", "34x23", "39x23", "44x23"],
             ["Quantity of horizontal heat exchangers", "pcs", 3, 4, 4, 4, 4],
             ["Volume of water in the boiler", "l", 59, 63, 68, 80, 85],
             ["Mass", "kg", 260, 280, 310, 360, 380],
             ["Required chimney draft", "Pa", 12, 13, 14, 15, 15],
         ],
         additionalData: [
             ["Minimum boiler operating temperature", "60 \u2070C"],
             ["Maximum boiler operating temperature", "90 \u2070C"],
             ["Heating efficiency", "90%"],
             ["Diameter of chimney inside/outside", "150/160 mm"],
             ["Dimensions of hydraulic connections", "G 1 \u00b9\u{141F}\u2074 inches"],
             ["Maximum operating pressure", "1.5 bar"],
         ],
         selections: [
             ["Kuro talpa", "230 l", "260 l", "320 l", "350 l"],
             [
                 ["Degiklis"],
                 ["12", "4-16 kW"],
                 ["16", "4-16 kW"],
                 ["20", "4-16 kW", "5-20 kW"],
                 ["26", "5-20 kW", "6-26 kW"],
                 ["36", "6-26 kW", "8-36 kW"],
             ]
         ],
         dimensions: [
             ["A", "mm", 1150, 1260, 1260, 1260, 1260],
             ["B", "mm", 440, 440, 490, 540, 590],
             ["C", "mm", 640, 640, 640, 690, 690],
         ],
         degiklis:  "„Kipi Rot Power“",
         degiklisData: [
             ["Power", "10-36 kW"],
             // ["Combustion Temperature", "850 \u2070C"],
             // ["Size", "890 x 380 x 450 mm"],
             // ["Connector", "D90mm"],
         ],
         icons: ["4vert_01", "kera_def_02", "rotac_03", "tubul_04", "2deg_05", "modern_06", "krypt_07"],
         iconLocations: [[245, 150], [208, 350], [320, 380], [245, 150], [242, 330], [315, 70], [355, 170]],
         description: "A pellet fuel boiler of the highest category and technical level with a four-way heat exchanger and a ceramic deflector. Cast iron shards and a ceramic deflector promote and ensure smooth combustion. This allows you to achieve a boiler efficiency of more than 90%. The fully automatic rotary burner is designed for burning lower quality and pellets with a higher ash content. The rotating combustion chamber helps burn the fuel completely and prevents the formation of slag. The controller controls all processes of boiler room management and boiler combustion.",
         burnerDescription: "Fully automatic pellet burner for burning pellets of good and medium quality. The burner has an automatic cleaning mechanism - a rotating combustion and air supply chamber, which helps the burner clean itself of ash and slag.",
         burnerImage: "../assets/kipiRotPower.png",
         burnerInstructionsPdf: "../assets/Pellet_Burner_Instruction_EN.pdf",
		 dimensionsImage: "../assets/pelletUniDimensions.svg",
         certificatePdf: "../assets/Bandymu sertifikatas Pellet Uni.pdf",
         instructionsPdf: "../assets/pellet_uni_instrukcija.pdf",
         techSpecPdf: "../assets/pellet_uni_technine_specifikacija.pdf",
         controllerInstructionsPdf: "../assets/Burner_Controller_Instruction_EN.pdf",
         connectionSchemePdf: "../assets/pelletUniPajungimasEN.pdf",
     }),
     new Katilas({
         id: 2,
         name: "Bio Kompakt",
         type: "Pellet fuel",
         imageUrl: "assets/bio_kompakt.png",
         pjuvis: "assets/bio_kompakt_pjuvis.png",
         feature: "New",
         price: 3400,
         pricing:[
             [12, 3400, 0, [0]],
             [16, 3550, 0, [0]],
             [20, 3700, 0, [0]],
         ],
         minPower: 12,
         maxPower: 20,
         power: [12, 16, 20],
         minArea: 120,
         maxArea: 200,
         efficiencyClass: "A++",
         fuelTypes: ["Granules"],
         heatExchangerWarranty: 6,
         certificate: 5,
         tableData: [
             ["Heated area", "up to m\u00b2", 120, 160, 200],
             ["Fuel capacity", "l/dm\u00b3", 120, 160, 160],
             ["Rotary Pellet Burner", "kW", "4-12", "4-16", "4-18"],
             ["Heat exchanger area", "m\u00b2", 1.7, 2.0 , 2.4],
             ["Quantity of horizontal heat exchangers", "pcs", 2, 2, 2],
             ["Volume of water in boiler", "l", 44, 50, 58],
             ["Mass", "kg", 155, 175, 195],
         ],
         additionalData: [
             ["Minimum boiler operating temperature", "60 \u2070C"],
             ["Maximum boiler operating temperature", "90 \u2070C"],
             ["Heating efficiency", "90%"],
             ["Diameter of chimney inside/outside", "130/140 mm"],
             ["Dimensions of hydraulic connections", "G 1 \u00b9\u{141F}\u2074 inches"],
             ["Maximum operating pressure", "1.5 bar"],
             ["Required chimney draft", "15-20 Pa"]
         ],
         selections: [
             [""],
             [""],
         ],
         dimensions: [
             ["A", "mm", 470, 520, 570],
             ["B", "mm", 645, 695, 754],
         ],
         degiklis: "„Kipi Rot Power“",
         degiklisData: [
             ["Power", "10-20 kW"],
         ],
         icons: ["kera_deg_01", "kera_def_02", "rotac_03", "tubul_04", "did_sil_05", "kompakt_06", "krypt_07"],
         iconLocations: [[290, 386], [230, 360], [375, 380], [298, 325], [298, 325], [0, 0], [414, 345]],
         description: "A compact class pellet fuel boiler with a ceramic combustion chamber, a ceramic deflector and a large heat exchanger. The long-lasting high-temperature ceramic supports combustion and ensures a smooth combustion process. This allows you to achieve a boiler efficiency of more than 90%. The fully automatic rotary burner is designed for burning lower quality and pellets with a higher ash content. The rotating combustion chamber helps burn the fuel completely and prevents the formation of slag. The controller controls all processes of boiler room management and boiler combustion.",
         burnerDescription: "Fully automatic pellet burner for burning pellets of good and medium quality. The burner has an automatic cleaning mechanism - a rotating combustion and air supply chamber, which helps the burner clean itself of ash and slag.",
         burnerImage: "../assets/kipiRotPower.png",
         burnerInstructionsPdf: "../assets/Pellet_Burner_Instruction_EN.pdf",
        dimensionsImage: "../assets/bioKompaktDimensions.svg",
        certificatePdf: "../assets/Bio Kompakt 10-20_Bandymu sertifikatas Nr. 02.24-ST [LT].pdf",
        instructionsPdf: "../assets/bio_kompakt_instrukcija.pdf",
        techSpecPdf: "../assets/bio_kompakt_technine_specifikacija.pdf",
        controllerInstructionsPdf: "../assets/Burner_Controller_Instruction_EN.pdf",
        connectionSchemePdf: "../assets/bioKompaktPajungimasEN.pdf",
     }),
     new Katilas({
         id: 3,
         name: "Ekon Max",
         type: "Solid fuel",
         imageUrl: "assets/ekon_max.png",
         pjuvis: "assets/ekon_max_pjuvis.png",
         feature: "Efficient",
         price: 1950,
         pricing:[
             [16, 1950, 0, [0]],
             [20, 2150, 0, [0]],
             [25, 2350, 0, [0]],
             [30, 2550, 0, [0]],
             [40, 2850, 0, [0]],
         ],
         minPower: 16,
         maxPower: 40,
         power: [16, 20, 25, 30, 40],
         minArea: 160,
         maxArea: 400,
         efficiencyClass: "A+",
         fuelTypes: ["Firewood", "Briquettes", "Sawdust", "Coal"],
         heatExchangerWarranty: 4,
         tableData: [
             ["Heated area", "up to m\u00b2", 160, 200, 250, 300, 400],
             ["Fuel loading", "l/dm\u00b3", 105, 125, 140, 160, 192],
             ["Fuel depth", "mm", 500, 500, 500, 500, 550],
             ["Fuel loading hole size", "cm", "29x23", "34x23", "39x23", "44x23", "44x23"],
             ["Heat exchanger area", "m\u00b2", 3.0, 3.4, 3.8, 4.2, 4.8],
             ["Quantity of horizontal heat exchangers", "pcs", 3, 3,3,3,3],
             ["Volume of water in boiler", "l", 66,72,78,85,115],
             ["Mass", "kg", 230,260,290,320,350],
             ["Diameter of chimney inside/outside", "mm", "150/160", "185/195", "185/195", "185/195", "185/195"],
         ],
         additionalData: [
             ["Minimum boiler operating temperature", "60 \u2070C"],
             ["Maximum boiler operating temperature", "90 \u2070C"],
             ["Heating efficiency", "85%"],
             ["Dimensions of hydraulic connections", "G 1 \u00b9\u{141F}\u00b2 inches"],
             ["Maximum operating pressure", "1.5 bar"],
             ["Required flue draft", ">20 Pa"]
         ],
         selections: [
             [""],
             [""],
         ],
         dimensions: [
             ["A", "mm", 1200, 1200, 1200, 1200, 1300],
             ["B", "mm", 420, 470, 520, 570, 570],
             ["C", "mm", 810, 810, 810, 810, 860],
         ],
         icons: ["nerud_01", "secondary_02", "2deg_03", "3horiz_04", "dumnut_05", "labdidikr_06", "2traukreg_07", "krypt_08"],
         iconLocations: [[224, 380], [218, 328], [254, 320], [302, 154], [360, 183], [308, 254], [408, 435], [430, 240] ],
         description: "The design used for many years with a double combustion chamber and three horizontal heat exchangers ensures the best performance of the boiler, easy maintenance and a long service life. The boiler is very suitable for burning firewood and coal. The largest area of the heat exchanger, the charge volume ensures a high efficiency factor and a very long burning time time. It's a great value for money boiler.",
         dimensionsImage: "../assets/ekonMaxDimensions.svg",
        techSpecPdf: "../assets/ekon_max_technine_specifikacija.pdf",
        connectionSchemePdf: "../assets/ekonMaxPajungimasEN.pdf",
        certificatePdf: "../assets/EU atitikties deklaracijos EKON MAX.pdf",
        instructionsPdf: "../assets/ekon_max_instrukcija.pdf",
     }),
     new Katilas({
         id: 4,
         name: "Ekon",
         type: "Solid fuel",
         imageUrl: "assets/ekon.png",
         pjuvis: "assets/ekon_pjuvis.png",
         feature: "Economical",
         price: 1300,
         pricing:[
             [10, 1300, 0, [0]],
             [13, 1500, 0, [0]],
             [16, 1650, 0, [0]],
             [20, 1850, 0, [0]],
         ],
         minPower: 10,
         maxPower: 20,
         power: [10, 13, 16, 20],
         minArea: 100,
         maxArea: 200,
         efficiencyClass: "A+",
         fuelTypes: ["Firewood", "Briquettes", "Sawdust", "Coal"],
         heatExchangerWarranty: 4,
         tableData: [
             ["Heated area", "up to m\u00b2", 100, 130, 160, 200],
             ["Fuel loading", "l/dm\u00b3", 65, 85, 100, 115],
             ["Fuel Depth", "mm", 400, 400, 400, 400],
             ["Fuel loading hole size", "cm", "29x23", "29x23", "34x23", "39x23"],
             ["Heat exchanger area", "m\u00b2", 1.9, 2.2, 2.6, 2.8],
             ["Quantity of horizontal heat exchangers", "pcs", 2,2,2,2],
             ["Volume of water in the boiler", "l", 41, 52, 56, 68],
             ["Mass", "kg", 160, 190, 210, 250],
             ["Diameter of chimney inside/outside", "mm", "150/160", "150/160", "150/160", "185/195"],
         ],
         additionalData: [
             ["Minimum boiler operating temperature", "60 \u2070C"],
             ["Maximum boiler operating temperature", "90 \u2070C"],
             ["Heating efficiency", "83%"],
             ["Dimensions of hydraulic connections", "G 1 \u00b9\u{141F}\u2074 inches"],
             ["Maximum operating pressure", "1.5 bar"],
             ["Required chimney draft", "15-20 Pa"]
         ],
         selections: [
             [""],
             [""],
         ],
         dimensions: [
             ["A", "mm", 910, 1100, 1100, 1150],
             ["B", "mm", 420, 420, 470, 520],
         ],
         icons: ["nerud_01", "antrinio_02", "2deg_03", "3horiz_04", "dumnut_05", "labdidikr_06", "2traukreg_07", "krypt_08"],
         iconLocations: [[224, 360], [230, 316], [258, 318], [304, 126], [360, 150], [320, 210], [405, 435], [420, 220] ],
         description: "The design used for many years with a double combustion chamber and horizontal heat exchangers ensures the best performance of the boiler, easy maintenance and a long service life. The boiler is very suitable for burning firewood, briquettes and coal. and a very long burning time. This is a boiler with an excellent price-quality ratio.",
         dimensionsImage: "../assets/ekonDimensions.svg",
        techSpecPdf: "../assets/ekon_technine_specifikacija.pdf",
        connectionSchemePdf: "../assets/ekonPajungimasEN.pdf",
        certificatePdf: "../assets/EU atitikties deklaracijos EKON.pdf",
        instructionsPdf: "../assets/ekon_instrukcija.pdf",
     }),
     new Katilas({
         id: 5,
         name: "Klasika",
         type: "Solid fuel",
         imageUrl: "assets/klasika.png",
         pjuvis: "assets/klasika_pjuvis.png",
         feature: "Easy to use",
         price: 1050,
         pricing:[
             [8, 1050, 0, [0]],
             [10, 1150, 0, [0]],
             [13, 1300, 0, [0]],
             [16, 1500, 0, [0]],
             [20, 1650, 0, [0]],
             [25, 1800, 0, [0]],
             [30, 1950, 0, [0]],
         ],
         minPower: 8,
         maxPower: 30,
         power: [8, 10, 13, 16, 20, 25, 30],
         minArea: 120,
         maxArea: 360,
         efficiencyClass: "A+",
         fuelTypes: ["Firewood", "Briquettes", "Coal"],
         heatExchangerWarranty: 4,
         tableData: [
             ["Heated area", "up to m\u00b2", 80, 100, 130, 160, 200, 250, 300],
             ["Fuel loading", "l/dm\u00b3", 60, 72, 90, 105, 125, 140, 160],
             ["Fuel Depth", "mm", 400, 400, 500, 500, 500, 500, 500],
             ["Fuel loading hole size", "cm", "24x23", "29x23", "29x23", "29x23", "34x23", "39x23", "44x23"],
             ["Heat exchanger area", "m\u00b2", 1.3, 1.5, 1.8, 2.3, 2.5, 2.8, 3.1],
             ["Quantity of horizontal heat exchangers", "pcs", 2,2,2,3,3,3,3],
             ["Volume of water in boiler", "l", 38,42,50,59,63,58,72],
             ["Mass", "kg", 135,150,170,200,225,250,275],
             ["Diameter of chimney inside/outside", "mm", "150/160", "150/160", "150/160", "150/160", "185/195", "185/195", " 185/195"],
             ["Dimensions of hydraulic connections", "inches", "G 1 \u00b9\u{141F}\u2074", "G 1 \u00b9\u{141F}\u2074","G 1 \u00b9\u{141F}\ u2074","G 1 \u00b9\u{141F}\u00b2","G 1 \u00b9\u{141F}\u00b2","G 1 \u00b9\u{141F}\u00b2","G 1 \u00b9 \u{141F}\u00b2"]
         ],
         additionalData: [
             ["Minimum boiler operating temperature", "60 \u2070C"],
             ["Maximum boiler operating temperature", "90 \u2070C"],
             ["Heating efficiency", "83%"],
             ["Maximum operating pressure", "1.5 bar"],
             ["Required thrust in chimney", "15-20 Pa"]
         ],
         selections: [
             [""],
             [""],
         ],
         dimensions: [
             ["A", "mm", 1000, 1000, 1000, 1200, 1200, 1200, 1200],
             ["B", "mm", 370, 420, 420, 420, 470, 520, 570],
             ["C", "mm", 570, 570, 670, 670, 670, 670, 670],
         ],
         icons: ["didplot_01", "dumnut_02", "labdidikr_03", "2traukreg_04", "krypt_05"],
         iconLocations: [[296, 146], [350, 150], [296, 250], [390, 440], [400, 148]],
         description: "The oldest used classic boiler design, with a large charging volume, a large heat exchanger. This ensures a very long burning time. The boiler is very suitable for burning firewood, moist solid fuel. It is an inexpensive, reliable, simple and time-tested boiler.",
         dimensionsImage: "../assets/klasikaDimensions.svg",
        techSpecPdf: "../assets/klasika_technine_specifikacija.pdf",
        connectionSchemePdf: "../assets/klasikaPajungimasEN.pdf",
        certificatePdf: "../assets/EU atitikties deklaracijos KLASIKA.pdf",
        instructionsPdf: "../assets/klasika_instrukcija.pdf",
     }),
     new Katilas({
         id: 6,
         name: "Kompakt",
         type: "Solid fuel",
         imageUrl: "assets/kompakt.png",
         pjuvis: "assets/kompakt_pjuvis.png",
         feature: "Inexpensive",
         price: 1100,
         pricing:[
             [12, 1100, 0, [0]],
             [16, 1250, 0, [0]],
             [20, 1400, 0, [0]],
         ],
         minPower: 12,
         maxPower: 20,
         power: [12, 16, 20],
         minArea: 120,
         maxArea: 200,
         efficiencyClass: "A+",
         fuelTypes: ["Firewood", "Briquettes", "Coal"],
         heatExchangerWarranty: 4,
         tableData: [
             ["Heated area", "up to m\u00b2", 120, 160, 200],
             ["Fuel loading", "l/dm\u00b3", 72, 90, 105],
             ["Fuel Depth", "mm", 400, 500, 500],
             ["Fuel loading hole size", "cm", "29x23", "29x23", "29x23"],
             ["Quantity of horizontal heat exchangers", "pcs", 2,2,3],
             ["Volume of water in boiler", "l", 42, 50, 59],
             ["Mass", "kg", 150, 170, 200],
             ["Dimensions of hydraulic connections", "inches", "G 1 \u00b9\u{141F}\u2074" , "G 1 \u00b9\u{141F}\u2074" , "G 1 \u00b9\u{141F}\ u00b2"],
         ],
         additionalData: [
             ["Diameter of chimney inside/outside", "150/160 mm"],
             ["Minimum boiler operating temperature", "60 \u2070C"],
             ["Maximum boiler operating temperature", "90 \u2070C"],
             ["Heating efficiency", "83%"],
             ["Maximum operating pressure", "1.5 bar"],
             ["Required flue draft", ">15 Pa"]
         ],
         selections: [
             [""],
             [""],
         ],
         dimensions: [
             ["A", "mm", 1000,1000,1200],
             ["B", "mm", 570,670,670],
         ],
         icons: ["didplot_01", "dumnut_02", "labdidikr_03", "2traukreg_04", "krypt_05"],
         iconLocations: [[296, 146], [350, 150], [296, 250], [390, 440], [400, 148]],
         description: "A solid fuel boiler of long-lasting and reliable construction, with a large heat exchanger and a large fuel charge. It is characterized by easy maintenance and operation, long burning time. Cleaning of the boiler is facilitated by a separate door. The compact design allows the boiler to be installed in small rooms. The smoke cut-off valve is designed for easier installation, and reduces the possibility of smoke entering the room when loading fuel. The boiler is equipped with a double thrust control valve, strong, easy-to-open doors, and durable cast iron grates that ensure better fuel combustion.",
         dimensionsImage: "../assets/kompaktDimensions.svg",
        techSpecPdf: "../assets/kompakt_technine_specifikacija.pdf",
        connectionSchemePdf: "../assets/kompaktPajungimasEN.pdf",
        certificatePdf: "../assets/EU atitikties deklaracijos KOMPAKT.pdf",
        instructionsPdf: "../assets/kompakt_instrukcija.pdf",
     }),
     new Katilas({
         id: 7,
         name: "Ignis",
         type: "Solid fuel",
         imageUrl: "assets/ignis.png",
         pjuvis: "assets/ignis_pjuvis.png",
         feature: "Practical",
         price: 1050,
         pricing:[
             [10, 1050, 0, [0]],
             [15, 1350, 0, [0]],
         ],
         minPower: 10,
         maxPower: 50,
         power: ["M 10", "D 15"],
         minArea: 100,
         maxArea: 150,
         efficiencyClass: "A+",
         fuelTypes: ["Firewood", "Briquettes", "Coal"],
         heatExchangerWarranty: 4,
         tableData: [
             ["Heated area", "up to m\u00b2", 100, 150],
             ["Fuel loading", "l/dm\u00b3", 70, 90],
             ["Fuel Depth", "mm", 300, 400],
             ["Fuel loading hole size", "cm", "34x26", "34x26"],
             ["Heat exchanger area", "m\u00b2", 1.2, 1.8],
             ["Quantity of vertical heat exchangers", "pcs", 1, 2],
             ["Volume of water in boiler", "l", 32, 42],
             ["Mass", "kg", 130, 190],
             ["Heating efficiency", "%", 76, 78],
             ["Diameter of chimney inside/outside", "mm", "130/140", "150/160"]
         ],
         additionalData: [
             ["Dimensions of hydraulic connections", "G 1 \u00b9\u{141F}\u2074 inches"],
             ["Minimum boiler operating temperature", "60 \u2070C"],
             ["Maximum boiler operating temperature", "90 \u2070C"],
             ["Maximum operating pressure", "1.5 bar"],
             ["Required draft in the chimney", "15-20 Pa"],
         ],
         selections: [
             [""],
             [""],
         ],
         icons: ["didplot_01", "viettrauk_02", "labdidikr_03", "vasziem_04", "krypt_05"],
         iconLocations: [[233, 190], [434, 260], [350, 220], [290, 240], [430, 204]],
         description: "Boiler-stove, a traditional, time-tested product. The boiler has a very large firewood charging capacity and a large heat exchanger. The design ensures high efficiency of the boiler and a long burning time. The high-quality, certified 5-millimeter-thick steel sheet used ensures a long service life. Perfect quality and reliable construction ensure a 4-year warranty for the product. The boiler can be used in summer and winter modes. It is possible to install an automatic draft regulator. The boiler is equipped with a high-quality cast iron hotplate, a reversible door, a convenient mechanical draft regulator.",
         dimensionsImage: "../assets/ignisDimensions.svg",
        techSpecPdf: "../assets/ignis_technine_specifikacija.pdf",
        connectionSchemePdf: "../assets/ignisPajungimasEN.pdf",
        certificatePdf: "../assets/EU atitikties deklaracijos IGNIS.pdf",
        instructionsPdf: "../assets/ignis_instrukcija.pdf",
     }),
     new Katilas({
         id: 8,
         name: "Agro Uni",
         type: "Agricultural waste",
         imageUrl: "assets/agro_uni.png",
         pjuvis: "assets/agro_uni_pjuvis.png",
         feature: "Eco-friendly",
         price: 2750,
         pricing:[
             [15, 2750, 0, [0.250]],
             [20, 3100, 0, [0.250]],
             [30, 3500, 0, [0.350]],
             [40, 4000, 0, [0.350]],
         ],
         minPower: 15,
         maxPower: 40,
         power: [15, 20, 30, 40],
         minArea: 150,
         maxArea: 400,
         efficiencyClass: "A+",
         fuelTypes: ["Grains", "Parts", "Pallets", "Schips", "Coal 0-50", "Firewood"],
         heatExchangerWarranty: 5,
         burnerWarranty: 3,
         tableData: [
             ["Heated area", "up to m\u00b2", 150, 200, 300, 400],
             ["Fuel depth", "mm", 450, 450, 500, 600],
             ["Fuel loading", "l/dm\u00b3", 35, 40, 65, 104],
             ["Zenono burner", "kW", "3-15", "3-20", "4-30", "8-50"],
             ["Heat exchanger area", "m\u00b2", 1.9, 2.5, 3.3, 4.2],
             ["Fuel loading hole size", "cm", "29x23", "34x23", "44x23", "49x23"],
             ["Quantity of horizontal heat exchangers", "pcs", 3, 4, 4, 4],
             ["Volume of water in the boiler", "l", 48, 62, 69, 100],
             ["Mass", "kg", 180, 230, 270, 310],
             ["Diameter of chimney inside/outside", "mm", "150/160", "150/160", "150/160", "185/195"],
         ],
         additionalData: [
             ["Fuel capacity with mixer", "400/600 l/dm\u00b3"],
             ["Minimum boiler operating temperature", "60 \u2070C"],
             ["Maximum boiler operating temperature", "90 \u2070C"],
             ["Heating efficiency", "90%"],
             ["Dimensions of hydraulic connections", "G 1 \u00b9\u{141F}\u2074 inches"],
             ["Maximum operating pressure", "1.5 bar"],
             ["Required chimney draft", "15-20 Pa"]
         ],
         selections: [
             ["Kuro talpa", "400 l", "600 l"],
             [""],
         ],
         dimensions: [
             ["A", "mm", 1100, 1200, 1200, 1280],
             ["B", "mm", 420, 470, 570, 620],
             ["C", "mm", 670, 670, 700, 800],
         ],
         degiklis: "„Zenono“",
         degiklisData: [
             ["Power", "15-50 kW"],
             // ["Combustion Temperature", "850 \u2070C"],
             // ["Size", "890 x 380 x 450 mm"],
             // ["Connector", "D90mm"],
         ],
         icons: ["zen_01", "nerud_02", "4vert_03", "tubul_04", "2deg_05", "talppam_06", "krypt_07"],
         iconLocations: [[240, 350], [240, 350], [240, 190], [240, 190], [235, 320], [410, 316], [320, 210]],
         description: "Extremely versatile agricultural waste boiler capable of burning various grain crops, agricultural waste, low-quality pellets, chips, sawdust and coal, firewood and other solid fuel in automatic mode, which is loaded through the middle door. A special mechanism for mixing the fuel tank, a self-cleaning burner, a large ash box and other solutions ensure the smooth burning of various types of fuel and the operation of the boiler. A large horizontal four-pass heat exchanger ensures convenient cleaning and a high boiler efficiency. The boiler is equipped with a „Zenono“ burner. We do not recommend equipping the boiler with a stainless steel hood.",
         burnerDescription: "A specialized burner capable of burning various grain crops, agricultural waste, low-quality pellets and various coal in automatic mode. It has a stainless steel cleaning mechanism that perfectly removes slag formed during combustion.",
         burnerImage: "../assets/zenonoDegiklis.png",
        dimensionsImage: "../assets/agroUniDimensions.svg",
        certificatePdf: "../assets/EU atitikties deklaracijos AGRO UNI.pdf",
        instructionsPdf: "../assets/agro_uni_instrukcija.pdf",
        techSpecPdf: "../assets/agro_uni_technine_specifikacija.pdf",
        controllerInstructionsPdf: "../assets/IE-70 naudojimo instrukcija N.pdf",
        connectionSchemePdf: "../assets/agroUniPajungimasEN.pdf",
     }),
     new Katilas({
         id: 9,
         name: "Universa",
         type: ["Pellet fuel", "Agricultural waste"],
         imageUrl: "assets/universa.png",
         pjuvis: "assets/universa_pjuvis.png",
         feature: "Popular",
         price: 2550,
         pricing:[
             [15, 2550, 0, [0, 50]],
             [20, 2900, 0, [0, 50]],
             [30, 3300, 0, [0, 50]],
             [40, 3850, 0, [0, 50]],
         ],
         minPower: 15,
         maxPower: 40,
         power: [15,20,30,40],
         minArea: 150,
         maxArea: 400,
         efficiencyClass: "A+",
         fuelTypes: ["Pellet", "Grains", "Peat", "Straw pellets", "Sunflower pellets", "Coal 0-50"],
         heatExchangerWarranty: 5,
         burnerWarranty: 3,
         tableData: [
             ["Heated area", "up to m\u00b2", 150,200,300,400],
             ["Fuel depth", "mm", 450,450,500,600],
             ["Fuel Charge", "l/dm\u00b3", 35,40,65,104],
             ["Zenono burner", "kW", "3-15", "3-20", "4-30", "8-50"],
             ["Heat exchanger area", "m\u00b2", 1.9,2.5,3.3,4.2],
             ["Fuel loading hole size", "cm", "29x23", "34x23", "44x23", "49x23"],
             ["Quantity of horizontal heat exchangers", "pcs", 3, 4, 4,4],
             ["Volume of water in boiler", "l", 48,62,69,100],
             ["Mass", "kg", 180,230,270,320],
             ["Diameter of chimney inside/outside", "mm", "150/160","150/160","150/160","185/195"],
         ],
         additionalData: [
             ["Fuel capacity", "230/300 l/dm\u00b3"],
             ["Minimum boiler operating temperature", "60 \u2070C"],
             ["Maximum boiler operating temperature", "90 \u2070C"],
             ["Heating efficiency", "90%"],
             ["Diameter of chimney inside/outside", "150/160 mm"],
             ["Dimensions of hydraulic connections", "G 1 \u00b9\u{141F}\u2074 inches"],
             ["Maximum operating pressure", "1.5 bar"],
             ["Required chimney draft", "15-20 Pa"]
         ],
         selections: [
             ["Kuro talpa", "200 l", "300 l"],
             [""],
         ],
         dimensions: [
             ["A", "mm", 1100, 1200, 1200, 1280],
             ["B", "mm", 420, 470, 570, 620],
             ["C", "mm", 670, 670, 700, 800],
         ],
         degiklis: "„Zenono“",
         degiklisData: [
             ["Power", "15-50 kW"],
             // ["Combustion Temperature", "850 \u2070C"],
             // ["Size", "890 x 380 x 450 mm"],
             // ["Connector", "D90mm"],
         ],
         icons: ["zen_01", "nerud_02", "4vert_03", "tubul_04", "2deg_05", "krypt_06"],
         iconLocations: [[240, 350], [240, 350], [246, 192], [246, 192], [234, 320], [330, 200]],
         description: "Extremely versatile pellet fuel boiler operating in automatic mode and designed to burn both good and poor quality pellets, as well as all grain crops, bulk agricultural waste, peat and coal, as well as firewood or other solid fuel that is loaded through middle door. Inexpensive, reliable and universal pellet fuel boiler, the design of which is specially adapted for the burning of various fuels. A large horizontal heat exchanger of four translations ensures convenient cleaning and a high efficiency factor. The boiler is equipped with a „Zenono“ burner.",
         burnerDescription: "A specialized burner capable of burning various grain crops, agricultural waste, low-quality pellets and various coals in automatic mode. It is equipped with a stainless steel cleaning mechanism that perfectly removes slag formed during combustion.",
         burnerImage: "../assets/zenonoDegiklis.png",
         dimensionsImage: "../assets/universaDimensions.svg",
         talpa: ['UT 200', 'UT 300'],
         talpaData:[
             ["A1", "mm", 1200, 1300],
             ["B1", "mm", 600, 750]
         ],
         certificatePdf: "../assets/EU atitikties deklaracijos UNIVERSA.pdf",
        instructionsPdf: "../assets/zenonoInstrukcijos.pdf",
        controllerInstructionsPdf: "../assets/IE-70 naudojimo instrukcija N.pdf",
        techSpecPdf: "../assets/universa_technine_specifikacija.pdf",
        connectionSchemePdf: "../assets/universaPajungimasEN.pdf",
        burnerInstructionsPdf: "../assets/universa_instrukcija.pdf",
    }),
     new Katilas({
         id: 10,
         name: "Pele Max",
         type: "Industrial",
         imageUrl: "assets/pelle_max.png",
         pjuvis: "assets/pelle_max_pjuvis.png",
         feature: "Economical",
         price: 6140,
         pricing:[
             [50, 6140, 460, [0, 100, 300]],
             [70, 7600, 900, [0, 100, 300]],
             [100, 10000, 700, [0, 100, 300]],
         ],
         minPower: 50,
         maxPower: 100,
         power: [50,70,100],
         minArea: 500,
         maxArea: 100,
         efficiencyClass: "A+",
         fuelTypes: ["Firewood", "Briquettes", "Coal", "Pellets"],
         heatExchangerWarranty: 5,
         burnerWarranty: 2,
         tableData: [
             ["Heated area", "up to m\u00b2", 500,700,1000],
             ["Fuel Depth", "mm", 680,680,980],
             ["Fuel Charge", "l/dm\u00b3", 105,145,280],
             ["Rotary pellet burner", "kW", "10-50", "15-70", "20-100"],
             ["Heat exchanger area", "m\u00b2", 6, 7.8, 11.8],
             ["Fuel Loading Hole Size", "cm", "50x26", "60x26", "70x26"],
             ["Quantity of horizontal heat exchangers", "pcs", 5,5,5],
             ["Volume of water in boiler", "l", 142,180,280],
             ["Mass", "kg", 500,650,890],
             ["Diameter of chimney inside/outside", "mm", "185/195", "185/195", "200/210"],
         ],
         additionalData: [
             ["Fuel capacity", "500/700/1000 l/dm\u00b3"],
             ["Minimum boiler operating temperature", "60 \u2070C"],
             ["Maximum boiler operating temperature", "90 \u2070C"],
             ["Heating efficiency", "90%"],
             ["Dimensions of hydraulic connections", "G 2 in"],
             ["Maximum operating pressure", "1.5 bar"],
             ["Required flue draft", ">20 Pa"]
         ],
         selections: [
             ["Kuro talpa", "500 l", "700 l", "1000 l"],
             [
                 ["Degiklis"],
                 ["50", "8-36 kW", "10-50 kW"],
                 ["70", "10-50 kW", "15-70 kW"],
                 ["100", "15-70 kW", "20-100 kW"],
             ]
         ],
         dimensions: [
             ["A", "mm", 1585, 1585, 1735],
             ["A1", "mm", 1275, 1355, 1430],
             ["B", "mm", 630, 730, 830],
             ["B1", "mm", 650, 750, 850],
             ["C", "mm", 890, 890, 1195],
             ["C1", "mm", 265, 340, 340],
         ],
         degiklis: "„Kipi Rot Power“",
         degiklisData: [
             ["Power", "36-100 kW"],
             // ["Combustion Temperature", "850 \u2070C"],
             // ["Size", "890 x 380 x 450 mm"],
             // ["Connector", "D90mm"],
         ],
         icons: ["56silum_01", "2deg_02", "rotac_03", "labdidikr_04", "krypt_05"],
         iconLocations: [[242, 162], [252, 320], [310, 380], [235, 286], [370, 190]],
         description: "Industrial universal pellet boiler with a modern and high-quality rotary burner, which can burn even lower quality pellets. It is a universal boiler that can burn pellets in fully automatic mode, as well as various solid fuels loaded by hand. The boiler has one of the largest heat exchanger areas on the market Five horizontal heat exchangers, many translations and a long smoke path ensure a very high boiler efficiency.",
         burnerDescription: "Fully automatic pellet burner for burning pellets of good and medium quality. The burner has an automatic cleaning mechanism - a rotating combustion and air supply chamber, which helps the burner clean itself of ash and slag.",
         burnerImage: "../assets/kipiRotPower.png",
         burnerInstructionsPdf: "../assets/pele_max_degiklio_instrukcija.pdf",
        dimensionsImage: "../assets/peleMaxDimensions.svg",
        certificatePdf: "../assets/EU atitikties deklaracijos PELE MAX.pdf",
        instructionsPdf: "../assets/pele_max_instrukcija.pdf",
        controllerInstructionsPdf: "../assets/Burner_Controller_Instruction_EN.pdf",
        techSpecPdf: "../assets/pele_max_technine_specifikacija.pdf",
        connectionSchemePdf: "../assets/peleMaxPajungimasEN.pdf",
     }),
     new Katilas({
         id: 11,
         name: "Agro Max",
         type: ["Agricultural waste", "Industrial"],
         imageUrl: "assets/agro_max.png",
         pjuvis: "assets/agro_max_pjuvis.png",
         feature: "Universal",
         price: 5700,
         pricing:[
             [50, 5700, 0, [0,200,400]],
             [70, 6900, 0, [0,200,400]],
             [100, 8500, 300, [0,200,400]],
         ],
         minPower: 50,
         maxPower: 100,
         power: [50, 70, 100],
         minArea: 500,
         maxArea: 100,
         efficiencyClass: "A",
         fuelTypes: ["Grains", "Parts", "Pallets", "Schips", "Coal 0-50", "Firewood"],
         heatExchangerWarranty: 5,
         burnerWarranty: 2,
         tableData: [
             ["Heated area", "up to m\u00b2", 500, 700, 1000],
             ["Fuel depth", "mm", 680, 680, 980],
             ["Fuel loading", "l/dm\u00b3", 140, 170, 290],
             ["Zenono burner", "kw", "15-70", "15-70", "20-100"],
             ["Heat exchanger area", "m\u00b2", 5.9, 7.1, 11],
             ["Fuel Loading Hole Size", "cm", "50x34", "60x34", "70x34"],
             ["Quantity of horizontal heat exchangers", "pcs", 5, 5, 5],
             ["Volume of water in the boiler", "l", 145, 165, 280],
             ["Mass", "kg", 550, 650, 930],
             ["Diameter of chimney inside/outside", "mm", "185/195", "185/195", "200/210"],
             ["Fuel capacity", "l/dm\u00b3", 400, 600, 800]
         ],
         additionalData: [
             ["Minimum boiler operating temperature", "60 \u2070C"],
             ["Maximum boiler operating temperature", "90 \u2070C"],
             ["Heating efficiency", "90%"],
             ["Dimensions of hydraulic connections", "G2 inches"],
             ["Maximum operating pressure", "1.5 bar"],
             ["Required flue draft", ">20 Pa"],
         ],
         selections: [
             ["Kuro talpa", "400 l", "600 l", "800 l"],
             [""],
         ],
         dimensions: [
             ["A", "mm", 1590, 1590, 1740],
             ["B", "mm", 630, 730, 830],
             ["C", "mm", 890, 890, 1190],
         ],
         degiklis: "„Zenono“",
         degiklisData: [
             ["Power", "50-100 kW"],
             // ["Combustion Temperature", "850 \u2070C"],
             // ["Size", "890 x 380 x 450 mm"],
             // ["Connector", "D90mm"],
         ],
         icons: ["zen_01", "nerud_02", "talppam_03", "2deg_04", "5silum_05", "krypt_06"],
         iconLocations: [[270, 350], [270, 350], [450, 306], [274, 306], [280, 162], [380, 274]],
         description: "This is a particularly versatile boiler for industrial use, intended for the automatic burning of various grain crops, agricultural waste, low-quality pellets and various coal. It is a product of the highest category and technical level, specially designed for burning such fuels. Large capacity with a mixing mechanism, by itself a self-cleaning burner, a large ash box and other advantages ensure easy use of the boiler. A large horizontal five-pass heat exchanger ensures convenient cleaning and a high efficiency factor.",
         burnerDescription: "A specialized burner capable of burning various grain crops, agricultural waste, low-quality pellets and various coal in automatic mode. It has a stainless steel cleaning mechanism that perfectly removes slag formed during combustion.",
         burnerImage: "../assets/zenonoDegiklis.png",
         dimensionsImage: "../assets/agroMaxDimensions.svg",
         talpa: ['AT 400', 'AT 600', 'AT 800'],
         talpaData:[
             ["A1", "mm", 1500, 1700, 1700],
             ["B1", "mm", 1080, 1080, 1190]
         ],
         certificatePdf: "../assets/EU atitikties deklaracijos AGRO MAX.pdf",
        instructionsPdf: "../assets/agro_max_instrukcija.pdf",
        techSpecPdf: "../assets/agro_max_technine_specifikacija.pdf",
        controllerInstructionsPdf: "../assets/IE-70 naudojimo instrukcija N.pdf",
        connectionSchemePdf: "../assets/agroMaxPajungimasEN.pdf",
     }),
     new Katilas({
         id: 12,
         name: "Bio Max",
         type: "Industrial",
         imageUrl: "assets/bio_max.png",
         pjuvis: "assets/bio_max_pjuvis.png",
         feature: "Practical",
         price: 3500,
         pricing:[
             [50, 3500, 0, [0]],
             [70, 4500, 0, [0]],
             [100, 5800, 0, [0]],
         ],
         minPower: 50,
         maxPower: 100,
         power: [50, 70, 100],
         minArea: 500,
         maxArea: 100,
         efficiencyClass: "A+",
         fuelTypes: ["Firewood", "Briquettes", "Coal"],
         heatExchangerWarranty: 4,
         tableData: [
             ["Heated area", "up to m\u00b2", 500, 700, 1000],
             ["Fuel loading", "l/dm\u00b3", 240, 380, 550],
             ["Fuel depth", "mm", 600, 800, 1000],
             ["Fuel Loading Hole Size", "cm", "40x50", "40x60", "40x70"],
             ["Heat exchanger area", "m\u00b2", 5, 7.1, 10],
             ["Quantity of horizontal heat exchangers", "pcs", 4, 4, 4],
             ["Volume of water in the boiler", "l", 130, 180, 235],
             ["Mass", "kg", 420, 570, 800],
             ["Diameter of chimney inside/outside", "mm", "200/210", "220/230", "250/260"],
         ],
         additionalData: [
             ["Dimensions of hydraulic connections", "G 2 inches"],
             ["Minimum boiler working temperature", "60 \u2070C"],
             ["Maximum boiler operating temperature", "90 \u2070C"],
             ["Heating efficiency", "83%"],
             ["Maximum operating pressure", "1.5 bar"],
             ["Required flue draft", ">20 Pa"],
         ],
         selections: [
             [""],
             [""],
         ],
         dimensions: [
             ["A", "mm", 1600, 1600, 1600],
             ["B", "mm", 630, 730, 830],
             ["C", "mm", 800, 1000, 1200],
         ],
         icons: ["didplot_01", "labdidikr_02", "2traukreg_03", "dumnut_04", "krypt_05"],
         iconLocations: [[312, 152], [300, 280], [414, 440], [370, 150], [440, 280]],
         description: "This is a classic design, reliable and easy-to-use industrial boiler. It also features a very large fuel charge, a large heat exchanger, a long service life and a low price. The boiler charge volume and heat exchanger area are among the largest on the market. This ensures a very long burning time. The boiler is very suitable for burning firewood, wetter fuel.",
         dimensionsImage: "../assets/bioMaxDimensions.svg",
        certificatePdf: "../assets/EU atitikties deklaracijos BIO MAX.pdf",
        techSpecPdf: "../assets/bio_max_technine_specifikacija.pdf",
        connectionSchemePdf: "../assets/bioMaxPajungimasEN.pdf",
        instructionsPdf: "../assets/bio_max_instrukcija.pdf",
     }),
];

