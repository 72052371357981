import React from 'react';


function Advert() {

  return <>
    <div className='advert'>
        <h1>INFORMUOJAME</h1>
        <p>Informuojame, kad informaciją apie paramą katilų keitimui galite rasti Lietuvos energetikos agentūros svetainėje (LEA, <a className='EnaLink' href='https://www.ena.lt/inpa-katilokeita/'>ena.lt</a>).</p>
        <p>Kvietimai teikti paraiškas skelbiami kiekvieno metų ketvirčio pirmąją darbo dieną. Sekantis katilų keitimo paramos etapas planuojamas preliminariai 2025 m. balandžio 1 d.</p>
        {/* <p></p> */}
    </div>
  </>;
}

export default Advert;