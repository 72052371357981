import React from 'react';
import '../header.css';
import { Link } from 'react-router-dom';
import { useLanguage } from './../LanguageContext';

function HeaderTree(props) {
  const {activeCategory} = props
  let [language, languageTexts, languageKatilai, toggleLanguage]= useLanguage();
  const translation = languageTexts;
  const changeLanguage = (event, changeTo) => {
    event.preventDefault();
    toggleLanguage(changeTo);
    //if (language !== changeTo) toggleLanguage();
  }

  const scrollToBurner = () => {
    document.getElementById("katiluScroll").scroll(0,0)
  }


  return <>
  <div className='headerLogoDiv'><Link to='/'><img src={'assets/logo_baltas_' + language + '.svg'} alt='logo' className='headerLogoImage'></img></Link>
            <div className='header_katilu_rusys'> 
              <Link to="/katilai_granulinio"
              onClick={() => {
                
                scrollToBurner();
    }}  
             className={activeCategory.includes("Granulinio kuro") ? "headerActive" : "headerInactive"}>{translation[2].text} {translation[0].text.toLowerCase()}</Link>
              <Link to="/katilai_kieto"
              onClick={() => {
                
                scrollToBurner();
    }}
             className={activeCategory.includes("Kieto kuro") ? "headerActive" : "headerInactive"}>{translation[3].text} {translation[0].text.toLowerCase()}</Link>
              <Link to="/katilai_zemes_ukio"
              onClick={() => {
                
                scrollToBurner();
    }}
            className={activeCategory.includes("Žemės ūkio atliekų") ? "headerActive" : "headerInactive"}>{translation[4].text} {translation[0].text.toLowerCase()}</Link>
              <Link to="/katilai_pramoniniai"
              onClick={() => {
                
                scrollToBurner();
    }}
             className={activeCategory.includes("Pramoniniai") ? "headerActive" : "headerInactive"}>{translation[5].text} {translation[0].text.toLowerCase()}</Link> 
            </div>
        </div>
        <div className='tree_links'> 
          
          <Link to='/informacija'>{translation[6].text}</Link>
          <span style={{paddingRight:"30px", paddingLeft:0}}></span>
          <Link to='/apiemus'>{translation[7].text}</Link>
          <span style={{paddingRight:"30px", paddingLeft:0}}></span>
          <a href='#contacts'>{translation[8].text}</a>
          <span style={{paddingRight:"30px", paddingLeft:0}}></span>

          {language === "lt" ? 
            <>
              <Link to='/esparama'>ES parama</Link>
              <span style={{paddingRight:"30px", paddingLeft:0}}></span>
            </>:
            <></>
          }
          
          <Link to='/atstovai'>{translation[72].text}</Link>
          <span style={{paddingRight:"30px", paddingLeft:0}}></span>
          {/* <Link to='/sheets'>{translation[108].text}</Link> */}
          <div className='language_header'>
              {/* <a href='https://google.com'>Lt</a> */}
              {/* <a href='https://google.com'>En</a> */}
              <Link to="/" className={language === "lt" ? "active-link" : null} onClick={event => changeLanguage(event, "lt")}>Lt</Link> 
              <Link to="/" className={language === "en" ? "active-link" : null} onClick={event => changeLanguage(event, "en")}>En</Link> 
              <Link to="/" className={language === "lv" ? "active-link" : null} onClick={event => changeLanguage(event, "lv")}>Lv</Link> 
              <Link to="/" className={language === "ee" ? "active-link" : null} onClick={event => changeLanguage(event, "ee")}>Ee</Link>
              <Link to="/" className={language === "pl" ? "active-link" : null} onClick={event => changeLanguage(event, "pl")}>Pl</Link>
        </div>
    </div>
  </>;
}

export default HeaderTree;

        