import { MOCK_KATILAI } from './katilai/MockKatilai';
import { MOCK_KATILAI_EN } from './katilai/MockKatilaiEn';
//import { MOCK_REPRESENTATIVES } from './treeInfo/mockRepresentatives';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import KatilasDetails from './katilai/KatilasDetails';
import Home from './Home';
import SellingRepresentatives from './treeInfo/sellingRepresentatives';
import SetupSpecialists from './treeInfo/setupSpecialists';
import AboutUs from './treeInfo/aboutUs';
import Information from './treeInfo/information';
import { MOCK_SPECIALISTS } from './treeInfo/mockSpecialists';
import texts_lt from './translations/lt.json';
import texts_en from './translations/en.json';
import React, {useState, useEffect} from 'react';
import {useLanguage } from './LanguageContext';
import SheetsMain from './sheets/sheetsMain';
import DragDropFiles from './sheets/sheetsUpload';
import { Katilas } from "./katilai/Katilas";
import { Specialist } from './treeInfo/specialist';
import LoadingPage from './loadingPage';
import ScrollToTop from './ScrollToTop';
import Esparam from './treeInfo/esparam';
import Katiluparama from './treeInfo/katiluparama';
//import { Representative } from './treeInfo/representative';

function OurRouter() {
  const [katilaiItems, setKatilaiItems] = useState([]);
  const [specItems, setSpecItems] = useState([]);
  //const [repItems, setRepItems] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  let [language, languageTexts, languageKatilai, toggleLanguage] = useLanguage();
  const translation = languageTexts;
  
  let katilasArray = MOCK_KATILAI;
  useEffect(() => {
    
    setKatilaiItems(MOCK_KATILAI);
    setIsLoaded(true);
    setSpecItems(MOCK_SPECIALISTS)
    // window.addEventListener('load', (event) => {
    //   //setIsLoaded(true);
    //   document.getElementById("loadingDivId").classList.toggle('fadeForLoading');
    //   console.log("here")
    // });
    // let repArray = [];
    // fetch(`${process.env.REACT_APP_SERVER_API_URL}/get/repinfo`,{
    //     method: 'GET', // *GET, POST, PUT, DELETE, etc.
    //   })
    //     .then(res => res.json())
    //     .then(
    //       (result) => {
    //         result.forEach(value =>{
    //           repArray.push(new Representative(value))
    //         });
    //         //TODO fix kai jau sheetsai pafixinti bus
    //         setRepItems(repArray);
    //       },
    //       (error) => {
    //         console.log(error);

    //         setRepItems(MOCK_REPRESENTATIVES);
    //       }
    // );

    }, []);

  const katilai = language === 'lt' ? katilaiItems : languageKatilai;

  // let list_reps = repItems.map(test =>{
  //   return test.location;
  // })
  // let uniqueReps = [...new Set(list_reps)];

  let list_specs = specItems.map(test =>{
    return test.location;
  })
  let uniqueSpecs = [...new Set(list_specs)];
  if(isLoaded)
  return (
    
      <Router>
          <ScrollToTop/>
          <div className="App">
            <Routes>
              <Route path="/sheets" element={<SheetsMain/>}/>
              <Route path="/sheetsUpload" element={<DragDropFiles/>}/>
              <Route path="/" element={<Home activeKatilai = {''}/>}/>
              <Route path="/katilai_granulinio" element={<Home activeKatilai = "Granulinio kuro" key={1}/>}/>
              <Route path="/katilai_kieto" element={<Home activeKatilai = "Kieto kuro" key={2}/>}/>
              <Route path="/katilai_zemes_ukio" element={<Home activeKatilai = "Žemės ūkio atliekų" key={3}/>}/>
              <Route path="/katilai_pramoniniai" element={<Home activeKatilai = "Pramoniniai" key={4}/>}/>
              {/* <Route
                exact
                path="/katilai/:id"
                element={<KatilasDetails katilas={katilai[0]}/>}
              /> */}
              <Route
                path="/katilai/:id"
                element={<KatilasDetails katilai={katilai}/>}
              />
              <Route path="/atstovai" element={<SellingRepresentatives/>}></Route>
              <Route path="/specialistai" element={<SetupSpecialists/>}></Route>
              <Route path="/apiemus" element={<AboutUs/>}></Route>
              <Route path="/informacija" element={<Information/>}></Route>
              <Route path="/esparama" element={<Esparam/>}></Route>
              <Route path="/katiluparama" element={<Katiluparama/>}></Route>
              {/* {uniqueReps.map(location =>{
                if(location === "") return <Route key={location + "rep"} path="/atstovai/kiti" element={<SellingRepresentatives activeReps={location}/>}></Route>;
                  else return <Route key={location + "rep"} path={"/atstovai/" + location} element={<SellingRepresentatives activeReps={location}/>}></Route>
              })} */}
              <Route path={"/atstovai/prekybos_centrai"} element={<SellingRepresentatives activeReps={"Prekybos centras"}/>}></Route>
              <Route path={"/atstovai/parduotuves"} element={<SellingRepresentatives activeReps={"Parduotuvė"}/>}></Route>
              <Route path={"/atstovai/el_parduotuves"} element={<SellingRepresentatives activeReps={"Elektroninė parduotuvė"}/>}></Route>
              <Route path={"/atstovai/kiti"} element={<SellingRepresentatives activeReps={"Atstovas"}/>}></Route>
              {uniqueSpecs.map(location =>{
                if(location === "") return <Route key={location + "spec"} path="/specialistai/kiti" element={<SetupSpecialists activeSpecialists={location}/>}></Route>;
                  else return <Route key={location + "spec"} path={"/specialistai/" + location} element={<SetupSpecialists activeSpecialists={location}/>}></Route>
              })}
            </Routes>
          </div>
      </Router>
  );
  else return <LoadingPage/>
}

export default OurRouter;